import { BrowserRouter, Routes, Route } from "react-router-dom";
import { router } from "./routes";

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {router.map((ele) => (
          <Route key={ele.path} path={ele.path} element={ele.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
