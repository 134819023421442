import React from "react";
import { DeleteIcon, FileIcon } from "../images";

function FileDetails({ file, handleDelete }) {
  return (
    <div className="my-auto">
      {file ? (
        <div className="flex py-8 px-6 border border-gray-500 rounded-lg w-96 h-16 bg-white justify-between items-center">
          <div className="flex items-center">
            <FileIcon />
            <p className="ml-2 font-semibold">
              {file.name}
              <br />
              <span className="font-normal">
                {Math.round(file.size / 1024)} KBytes
              </span>
            </p>
          </div>
          <DeleteIcon
            className="text-red-800 cursor-pointer"
            onClick={() => handleDelete()}
          />
        </div>
      ) : (
        <div className="w-96"></div>
      )}
    </div>
  );
}

export default FileDetails;
