import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Pagination, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../../components/Footer";
// import NavbarContent from "../../components/Navbar/NavbarContent";
import getURL from "../../routes/api-urls";
// import MiddleContainer from "../../container/MiddleContainer";
import SnackAlert from "../../components/SnackAlert";
import useSnack from "../../hooks/useSnack";
import "../../styles/extractedPage.css";
import { convertJSONToCSVKleeto } from "../../utils/jsonToCSV";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ResultPage() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const firstUpdate = useRef(true);
  const { showMessage, getProps } = useSnack();

  const [extractedData, setExtractedData] = useState(false);
  const [jobFile, setJobFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [formatedData, setFormatedData] = useState(null);
  const [headerName, setHeaderName] = useState(
    "Experience XT Invoice Processing"
  );
  useEffect(() => {
    var done = false;
    const getJobDetails = () => {
      axios
        .get(`${getURL("kleeto").getResult}/${jobId}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN_KLEETO}`,
          },
        })
        .then(({ data }) => {
          if (data.result?.isProcessing) {
            setTimeout(() => getJobDetails(), 2000);
          } else {
            const newData1 = [];
            const newData2 = [];
            Object.keys(data.result.data.formData).forEach((label, i) => {
              if (i % 2)
                newData2.push({
                  label,
                  value: data.result.data.formData[label],
                });
              else
                newData1.push({
                  label,
                  value: data.result.data.formData[label],
                });
            });
            setFormatedData({ row1: newData1, row2: newData2 });
            done = true;
            setExtractedData(data.result);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          showMessage("error", error.message);
        });
    };

    const getJobFile = (count = 0) => {
      axios
        .get(`${getURL("OCR").getFile}/${jobId}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN_KLEETO}`,
          },
        })
        .then(({ data }) => {
          setHeaderName(`Extracted ${data.result.docType} Output:`);
          setJobFile(data.result);
          const newData1 = [];
          const newData2 = [];
          if (done === false) {
            data.result.fields.forEach((label, i) => {
              if (i % 2)
                newData2.push({
                  label,
                  value: "**",
                });
              else
                newData1.push({
                  label,
                  value: "**",
                });
            });
            if (done === false) {
              setFormatedData({ row1: newData1, row2: newData2 });
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          if (count < 5) {
            setTimeout(() => getJobFile(count + 1), 1000);
          }
        });
    };

    if (firstUpdate.current) {
      getJobDetails();
      getJobFile();
      firstUpdate.current = false;
    }
  }, [jobId]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleExport = async () => {
    const csv = await convertJSONToCSVKleeto(extractedData.data.formData);
    const blob = new Blob([csv], { type: "text/csv" });
    const csvURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${extractedData.docName}_${extractedData.docType}.csv`;
    link.click();

    URL.revokeObjectURL(csvURL);
  };

  return (
    <>
      <SnackAlert {...getProps} />
      <div className="bg-zinc-100 pt-8">
        <div style={{ maxWidth: "1500px", margin: "auto" }} className="pb-8">
          <div className="flex justify-between">
            <h1 className="text-[#2E0040] sub-heading font-semibold leading-10">
              {headerName}
            </h1>
            <div className="d-flex">
              <button
                className="mb-8 text-center text-white text-base font-bold leading-relaxed tracking-wide w-32 h-12 rounded-lg shadow bg-[#6A2588] p-2.5 disabled:opacity-30"
                disabled={!extractedData}
                onClick={() => handleExport()}
              >
                Export
              </button>
              <button
                className="ml-5 mb-8 text-center text-white text-base font-bold leading-relaxed tracking-wide w-32 h-12 rounded-lg shadow bg-[#6A2588] p-2.5"
                onClick={() => navigate("/kleeto")}
              >
                Back
              </button>
            </div>
          </div>
          {jobFile ? (
            <p className="mb-2">
              <span className="font-bold text-lg">File Name</span>
              {`: ${jobFile.docName}`}
            </p>
          ) : null}
          <div className="flex justify-between space-x-8">
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Field Name</StyledTableCell>
                    <StyledTableCell>Value of the Field</StyledTableCell>
                    <StyledTableCell>Field Name</StyledTableCell>
                    <StyledTableCell>Value of the Field</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formatedData
                    ? formatedData.row1.map((row, i) => (
                        <>
                          <StyledTableRow key={i}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{ fontWeight: "bold" }}
                            >
                              {row?.label}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row?.value === "**" ? (
                                <Skeleton
                                  variant="rectangular"
                                  width={"8rem"}
                                  height={"2rem"}
                                />
                              ) : (
                                row.value
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{ fontWeight: "bold" }}
                            >
                              {formatedData?.row2[i]?.label}
                            </StyledTableCell>
                            <StyledTableCell>
                              {/* {formatedData?.row2[i]?.value || ""} */}
                              {formatedData?.row2[i]?.value === "**" ? (
                                <Skeleton
                                  variant="rectangular"
                                  width={"8rem"}
                                  height={"2rem"}
                                />
                              ) : (
                                formatedData?.row2[i]?.value
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <div
                className="border"
                style={{
                  maxWidth: "650px",
                  maxHeight: "850px",
                  overflowY: "scroll",
                }}
              >
                {!jobFile ? (
                  <Skeleton
                    variant="rectangular"
                    width={"650px"}
                    height={"850px"}
                  />
                ) : (
                  <>
                    <Document
                      file={jobFile.docImage}
                      loading={
                        <Skeleton
                          variant="rectangular"
                          width={"650px"}
                          height={"850px"}
                        />
                      }
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={() => {
                        console.log("PDF Load Error!");
                      }}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </>
                )}
              </div>
              <div className="flex justify-center">
                <Pagination
                  className="mt-4"
                  count={numPages}
                  onChange={(event, value) => setPageNumber(value)}
                  page={pageNumber}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#2E0040]">
          <div className="py-12" style={{ maxWidth: "1500px", margin: "auto" }}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default ResultPage;
