import React, { useEffect, useState } from "react";
import NavbarContent from "../../components/Navbar/NavbarContent";
import { useNavigate } from "react-router-dom";
import MiddleContainer from "../../container/MiddleContainer";
import PointsCard from "../../components/Playground/PointsCard";
import { ReactComponent as Image1 } from "../../assets/up_image_1.svg";
import { ReactComponent as Image2 } from "../../assets/up_image_2.svg";
import { ReactComponent as Image3 } from "../../assets/up_image_3.svg";
import Footer from "../../components/Footer";
import FileDetails from "../../components/FileDetails";
import useSnack from "../../hooks/useSnack";
import axios from "axios";
import getURL from "../../routes/api-urls";
import SnackAlert from "../../components/SnackAlert";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UploadFile2 from "../../components/Upload/UploadFile2";
import { CircularProgress } from "@mui/material";

const kleetoDocTypes = [
  {
    value: "Invoice",
  },
  {
    value: "Payment Voucher",
  },
  {
    value: "Travel Expenses",
  },
];

function UploadPage() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [docType, setDocType] = React.useState(null);
  const [isUploading, setUploading] = useState(false);

  const points = [
    [
      'Click the "Upload Invoice" button',
      "Select your invoice PDF file(less then 2048KB and not more than 6 pages)",
      'Click "Submit"',
    ],
    [
      "Wait for the system to process your invoice. A process indicator will show the processing status.",
      "This may take a few moments.",
    ],
    [
      "Wait for the system to process your invoice. A process indicator will show the processing status.",
      "This may take a few moments.",
    ],
  ];

  const { showMessage, getProps } = useSnack();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFileUpload = (file) => {
    if (isUploading) return;
    setUploading(true);

    let payload = new FormData();
    payload.append("files", file);
    payload.append("docType", docType);
    payload.append("labelType", "Import");
    payload.append("internal", true);

    axios
      .post(getURL("kleeto").extract, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN_KLEETO}`,
        },
      })
      .then(({ data }) => {
        if (data.status) {
          showMessage("success", "File Upload Success");
          navigate(`/kleeto/${data.result[0].id}`);
        }
        setUploading(false);
      })
      .catch((error) => {
        console.log(error);
        showMessage("error", error.message);
        setUploading(false);
      });
  };

  const handleDelete = () => setFile(null);

  const handleChange = (event) => {
    setDocType(event.target.value);
  };

  return (
    <div>
      <NavbarContent>
        <ul className="navbar-nav">
          <li className="nav-item">
            <button
              className="text-center text-white text-base font-bold leading-relaxed tracking-wide w-32 h-12 rounded-lg shadow bg-[#6A2588] p-2.5"
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </li>
        </ul>
      </NavbarContent>
      <SnackAlert {...getProps} />
      <MiddleContainer bgColor={"bg-zinc-100 pt-28"}>
        <div className="text-[#2E0040] sub-heading font-semibold leading-10">
          Experience XT - Intelligent Document Automation
        </div>
        <div className="flex justify-between mt-8 space-x-20">
          <PointsCard
            Icon={Image1}
            title={"Upload Your Invoice"}
            points={points[0]}
          />
          <PointsCard
            Icon={Image2}
            title={"Processing Your Invoice"}
            points={points[1]}
          />
          <PointsCard
            Icon={Image3}
            title={"Processing Your Invoice"}
            points={points[2]}
          />
        </div>
      </MiddleContainer>
      <MiddleContainer bgColor={"bg-white"}>
        <div className="flex justify-between">
          <div className="w-3/5">
            <UploadFile2
              setFile={setFile}
              isCheck={false}
              showMessage={showMessage}
            />
          </div>
          <div className="w-96 h-auto flex flex-col justify-end">
            <div className="self-center my-auto">
              <FileDetails file={file} handleDelete={handleDelete} />
            </div>
            <div className="flex space-x-5">
              <Box sx={{ minWidth: "242px" }} className="mb-2">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    File Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={docType}
                    label="docType"
                    onChange={handleChange}
                    disabled={!file}
                  >
                    {kleetoDocTypes.map((type, i) => (
                      <MenuItem key={i} value={type.value}>
                        {type.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <button
                disabled={!docType || !file}
                className="text-center text-white text-base font-bold leading-relaxed tracking-wide w-32 h-14 rounded-lg shadow bg-[#6A2588] p-2.5 disabled:cursor-not-allowed disabled:opacity-50"
                onClick={() => handleFileUpload(file)}
              >
                {isUploading ? <CircularProgress size={24} /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </MiddleContainer>
      <MiddleContainer bgColor={"bg-[#2E0040]"}>
        <Footer />
      </MiddleContainer>
    </div>
  );
}

export default UploadPage;
