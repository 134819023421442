import React from "react";
import { Link } from "react-router-dom";

function ServiceLevels() {
  return (
    <div className="text-justify">
      <p className="text-stone-900 text-base font-normal ">
        Effective Date: 10th January 2024
        <br />
        <br />
        This Support Policy is to be read in conjunction with the Orders
        mentioned in the{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="/terms-of-service"
        >
          Terms of Service{" "}
        </Link>
        , and is available to you (“Customer”) upon acceptance of the Order.
        <br />
        <br />
        Capitalized terms used in this SLA that are not defined herein have the
        meanings given to them in the Terms of Use (“Agreement”).
        <br />
        <br />
        OpenTurf will use reasonable efforts to make the Service available with
        an uptime of 99.5% of each calendar month ("Target Availability").
        <br />
        <br />
        Exclusions. The calculation of uptime will not include unavailability to
        the extent due to: (a) use of the Service by Customer in a manner not
        authorized in the Agreement or the applicable Documentation; (b) general
        Internet problems, force majeure events or other factors outside of
        OpenTurf's reasonable control; (c) Customer's equipment, software,
        network connections or other infrastructure; (d) Subprocessors’ acts or
        omissions; or (e) Scheduled Maintenance or reasonable emergency
        maintenance.
        <br />
        <br />
        Scheduled Maintenance. "Scheduled Maintenance" means OpenTurf's
        scheduled routine maintenance of the Services for which OpenTurf shall
        notify you at least twenty-four (24) hours in advance. Scheduled
        Maintenance will not exceed eight (8) hours per month. OpenTurf
        typically performs Scheduled Maintenance once per month.
        <br />
        <br />
        Remedy for Failure to Meet Target Availability. If there is a verified
        failure of a Service to meet Target Availability in two (2) consecutive
        months, then Customer may terminate the applicable Order by sending
        written notice of termination within thirty (30) days after the end of
        the second such month. In such a case, OpenTurf will refund to the
        Customer, fees that the Customer would have prepaid for use of such
        Service for the terminated portion of the applicable Order. This
        termination and refund right is Customer's sole and exclusive remedy,
        and OpenTurf's sole and exclusive liability, for OpenTurf's failure to
        meet the Target Availability for the Service.
        <br />
        <br />
      </p>
    </div>
  );
}

export default ServiceLevels;
