import React from "react";
import TrialBtn from "../InvoiceProcessing/TrialBtn";
import { ZohoBooksIcon, FexoLogoIcon } from "../../images";
import { Link } from "react-router-dom";

function ZohoBooksIntegrationGuideContent() {
  return (
    <div className="pt-16 ">
        <body className="w-full">
        <div class = "text-stone-900 text-2xl font-[1000] flex justify-center">
        Fexo and Zoho Books Integration Guide
        </div>
        <div class = "text-stone-900 pt-6 font-[500] text-lg">
        The integration between Fexo and Zoho Books allows you to automate the extraction of invoices and bank statements and post them directly to Zoho Books. This document provides a step-by-step guide on how to set up and use this integration to streamline your bookkeeping operations.
        </div>
          <div class = "text-stone-900 pt-6 font-[500] text-xl">


          <div class="mb-2 text-l"><b>1. Setting up the integration</b></div>
          <div className="text-lg">
            <div class="ml-2"><b>Step 1. Log in to Fexo:</b> <br/></div>
            <ul className="list-disc mb-4">
                <li>Visit the Fexo login page and enter your credentials.</li>
                <li>If you do not have an account, sign up and complete the onboarding process.</li>
            </ul>
            <div class="ml-2"><b>Step 2: Access Configuration Settings
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>Once logged in, navigate to the <b>Configuration</b> section in your Fexo dashboard.
                </li>
                <li>Select <b>Output Destinations</b> from the options.
                </li>
            </ul>
            <div class="ml-2"><b>Step 3: Select Zoho Books as Output Destination
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>From the list of output options, choose <b>Zoho Books.</b>
                </li>
                <li>Click on the <b>Sign In</b> button to begin the integration process.
                </li>
            </ul>
            <div class="ml-2"><b>Step 4: Sign in to Zoho Books
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>A prompt will appear, asking you to log in to your Zoho Books account.
                </li>
                <li>Enter your Zoho Books credentials and authorize the connection between Fexo and Zoho Books.
                </li>
            </ul>
            <div class="ml-2"><b>Step 5: Complete the Integration
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>Once authenticated, Zoho Books will be linked to your Fexo account, allowing for the seamless transfer of extracted financial data.
                </li>
            </ul>
            </div>


            <div class="mb-2"><b>2. Uploading Invoices and Bank Statements</b></div>
            <div className="text-lg">
            <div class="ml-2"><b>Step 1: Upload Financial Documents
            </b> <br/></div>
            <ul className="list-disc mb-4">
                <li>After configuring Zoho Books, click on the <b>Add Documents button</b> in Fexo Dashboard.
                </li>
                <li>Select the document type to add your invoices or bank statements.
                    <ul className="list-decimal">
                        <li>You can upload files in formats such as PDF, or scanned image formats.
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="ml-2"><b>Step 2: Data Extraction
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>Once uploaded, Fexo will automatically extract key information from the document. This includes:
                <ul className="list-decimal">
                        <li>For <b>Invoices</b>: Vendor details, line items, amounts, tax information, and due dates.
                        </li>
                        <li>For <b>Bank Statements</b>: Transaction details, dates, and balances.
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="ml-2"><b>Step 3: Review Extracted Data
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>Review the extracted data in Fexo before posting it to Zoho Books. You can make any necessary edits or adjustments.
                </li>
            </ul>
            </div>


            <div class="mb-2"><b>3. Posting Data to Zoho Books</b></div>
            <div className="text-lg">
            <div class="ml-2"><b>Step 1: Post to Zoho Books
            </b> <br/></div>
            <ul className="list-disc mb-4">
                <li>After reviewing the extracted details, Click the <b>Submit</b>  button on the Summary screen to initiate the transfer.
                </li>
                <li>Fexo will push the data directly into your Zoho Books account under the relevant modules:
                <ul className="list-decimal">
                        <li><b>Invoices:</b> Will appear under your <b>Sales</b> or <b>Purchases</b> section.
                        </li>
                        <li><b>Bank Transactions</b>: Will be posted to your <b>Banking module.</b></li>
                    </ul>
                </li>
            </ul>
            <div class="ml-2"><b>Step 2: Confirmation
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>Once the data is successfully posted, you will receive a confirmation pop up in Fexo.
                </li>
                <li>You can now view the posted invoices or transactions directly within your Zoho Books account.
                </li>
            </ul>
            </div>
            

            <div class="mb-2"><b>4. Managing Vendor and Bank Information
            </b></div>
            <div className="text-lg">
            <ul className="list-disc mb-4">
                <li>Fexo will automatically pull vendor and bank details from Zoho Books to ensure seamless data matching.</li>
                <li>You can manage or edit this information within Zoho Books, and the changes will be reflected in Fexo in real time.</li>
            </ul>
            </div>



            <div class="mb-2"><b>5. FAQ</b></div>
            <div className="text-lg">
            <div class="ml-2"><b>Q1: How do I troubleshoot connection issues between Fexo and Zoho Books?
            </b> <br/></div>
            <ul className="list-disc mb-4">
                <li>Ensure that your Zoho Books account credentials are correct and that you have provided necessary permissions for Fexo to access your Zoho Books account.
                </li>
                <li>If the issue persists, contact Fexo support at <b>hello@fexo.io</b>
                </li>
            </ul>
            <div class="ml-2"><b>Q2: Can I post multiple invoices at once?
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>Yes, Fexo allows batch posting of multiple invoices or bank transactions. Simply select the documents you wish to upload and post them to Zoho Books in one go.
                </li>
            </ul>
            <div class="ml-2"><b>Q3: How secure is the data transfer between Fexo and Zoho Books?
            </b><br/></div>
            <ul className="list-disc mb-4">
                <li>Fexo uses encryption and follows industry-standard security protocols to ensure the safe transfer of data between systems.
                </li>
            </ul>
            </div>

            <div class="mb-2"><b>6. Contact Support
            </b></div>
            <div className="text-lg">
            If you encounter any issues or need further assistance, feel free to reach out to our support team:

            <ul className="list-disc mb-4">
                <li>Email: hello@fexo.io</li>
                <li>Phone: 9980567065</li>
            </ul>
            </div>
            

        </div>
        </body>
      </div>
  );
}

export default ZohoBooksIntegrationGuideContent;
