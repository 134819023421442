import React from "react";
import { Link } from "react-router-dom";

function SecurityPolicy() {
  return (
    <div>
      <p className="text-stone-900 text-base font-normal ">
        Effective Date: 10th January 2024
        <br />
        <br />
        OpenTurf places utmost importance on safeguarding customer data. This
        security policy outlines the organizational and technical measures
        implemented on Fexo Websites to prevent unauthorized access, use,
        alteration, or disclosure of customer data. Unless specified otherwise,
        this policy pertains to OpenTurf activities within its instance on
        Amazon Web Services ("AWS"), Microsoft 365 and Azure (“MS”) and Google
        Play Services (“Google”). It is recommended to review our{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="/terms-of-service"
        >
          Terms of Service
        </Link>{" "}
        and
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="/privacy-policy"
        >
          {" "}
          Privacy Policy
        </Link>{" "}
        for a comprehensive understanding of OpenTurf practices.
        <br />
        <br />
        Security Team
        <br />
        <br />
        Our dedicated infrastructure and security team comprises individuals
        with significant experience in designing, building, and operating highly
        secure internet-facing systems across geographies and companies of
        varied sizes.
        <br />
        <br />
      </p>
      <p className="text-stone-900 text-base font-normal ">1. Best Practices</p>
      <br />
      <ul>
        <li>
          Incident Response Plan
          <br />
          <br />
          OpenTurf has instituted a formal procedure for security events, with
          all staff members educated on our policies. Detected security events
          are escalated to our emergency alias, triggering prompt response and
          resolution. Post-event, a comprehensive post-mortem analysis is
          conducted, reviewed across the company, and includes action items for
          future prevention.
          <br />
          <br />
          OpenTurf commits to notifying you promptly in writing upon verifying a
          security breach affecting your data. Further details about our
          incident response plan are available here.
          <br />
          <br />
        </li>
        <li>
          Build Process Automation
          <br />
          <br />
          Functional and frequently used automation is in place for secure and
          reliable changes to our application and operating platform within
          minutes. With multiple code deployments daily, we ensure rapid
          deployment of security fixes when necessary.
          <br />
          <br />
        </li>
      </ul>
      <p className="text-stone-900 text-base font-normal ">2. Infrastructure</p>
      <br />
      All OpenTurf services operate in the cloud, with no proprietary routers,
      load balancers, DNS servers, or physical servers. Hosted on AWS, MS and
      Google facilities, our services and data benefit from the security
      protocols of the respective cloud infrastructure provider. The details are
      mentioned on the appropriate websites of AWS, MS and Google. Disaster
      recovery considerations have been integral to our service architecture.
      <br />
      <br />
      Our servers reside within a dedicated virtual private cloud (VPC) with
      network access control lists (ACLs) preventing unauthorized access to our
      internal network. OpenTurf employs backup solutions for datastores
      containing customer data.
      <br />
      <br />
      3. Data
      <br />
      <br />
      All customer data is stored in India within multi-tenant datastores. While
      individual datastores per customer are not maintained, robust privacy
      controls in our application code ensure data separation, with regular unit
      and integration tests validating their effectiveness. The configuration
      and patching of systems processing customer data align with
      industry-recognized hardening standards.
      <br />
      <br />
      Certain subprocessors are engaged for processing customer data, listed{" "}
      <Link
        className="underline text-purple-800"
        target="_blank"
        rel="noopener noreferrer"
        to="/subprocessors"
      >
        here
      </Link>
      , subject to updates by OpenTurf.
      <br />
      <br />
      4. Data Transfer
      <br />
      <br />
      All data transmitted to or from the Fexo Website is encrypted in transit
      using 256-bit encryption. Our API and application endpoints operate
      exclusively on TLS/SSL, earning an "A+" rating on SSL Labs' tests.
      Additionally, industry-standard AES-256 encryption secures data at rest.
      <br />
      <br />
      5. Authentication
      <br />
      <br />
      OpenTurf is served entirely over HTTPS, with no corporate resources or
      additional privileges from being on OpenTurf's network. Two-factor
      authentication (2FA) and robust password policies on AWS, MS and Google
      enhance access protection to cloud services.
      <br />
      <br />
      6. Permissions and Admin Controls
      <br />
      <br />
      OpenTurf offers configurable permission levels for employees to access the
      Fexo Website, covering app settings, billing, user data, and messaging
      capabilities.
      <br />
      <br />
      7. Security Audits and Certifications
      <br />
      <br />
      Bi-annual engagements with third-party auditors assess our codebase, with
      SOC 2 Type II compliance and an attested SOC 2 Type II report. Information
      on AWS, MS and Google security certifications are accessible at their
      respective security websites.
      <br />
      <br />
      All payment instrument processing is handled by Stripe, adhering to their
      security practices as mentioned on their website.
      <br />
      <br />
      8. Customer Responsibilities
      <br />
      <br />
      Customers are responsible for managing their user accounts and roles
      within Fexo Websites, ensuring account and credential protection through
      2FA. Compliance with service agreements, including legal requirements, is
      imperative. Prompt notification to OpenTurf is essential in cases of
      compromised credentials or suspected security threats affecting services
      or accounts. Security penetration tests or assessments require OpenTurf'
      express advance written consent.
      <br />
      <br />
      We can be reached at info@openturf.in.
      <br />
      <br />
    </div>
  );
}

export default SecurityPolicy;
