import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Pagination, Skeleton } from "@mui/material";

function FloatingDocumentViewer({ jobFile }) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState({
    transform: "scale(0.2)",
    right: "-80px",
    top: "-200px",
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleClickAway = () => {
    setScale({ transform: "scale(0.2)", right: "-80px", top: "-200px" });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className="fixed border border-[#2E0040] bg-white rounded-md p-0.5 z-30"
        style={scale}
        onClick={() => {
          setScale({ transform: "scale(0.7)", right: 0, top: "-40px" });
        }}
      >
        <div className="relative max-w-fit">
          <div className="border">
            {!jobFile ? (
              <Skeleton
                variant="rectangular"
                width={"650px"}
                height={"650px"}
              />
            ) : (
              <>
                <Document
                  file={jobFile.docImage}
                  loading={
                    <Skeleton
                      variant="rectangular"
                      width={"650px"}
                      height={"650px"}
                    />
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={() => {
                    console.log("PDF Load Error!");
                  }}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              </>
            )}
          </div>
          <div className="w-full flex justify-center absolute bottom-0 mb-5">
            <div className="flex border rounded-lg p-1 bg-gray-300 items-center z-10">
              <Pagination
                count={numPages}
                onChange={(event, value) => setPageNumber(value)}
                page={pageNumber}
              />
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default FloatingDocumentViewer;
