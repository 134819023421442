import React from "react";
import ImageCard from "../ImageCard";
import { ReactComponent as Image1 } from "../../assets/Image_1.svg";
import { ReactComponent as Image2 } from "../../assets/Image_2.svg";
import { ReactComponent as Image3 } from "../../assets/Image_3.svg";
import { ReactComponent as Image4 } from "../../assets/Image_4.svg";
// import TryButton from "./TryButton";
import TrialBtn from "./TrialBtn";

function Transform(){
    return (
      <div>
        <h2 className="text-[#2E0040] sub-heading mb-8">
          Transform Dark Data To Financial Records
        </h2>
        <div className="grid justify-center xl:mx-16 md:grid-cols-2 gap-x-20 2xl:gap-x-10 gap-y-16 md:gap-y-20">
          <ImageCard
            Icon={Image1}
            title1={"Augmented "}
            title2={"Automation"}
            content={
              "Fexo complements data extraction with human verification to ensure 100% accurate entries in the accounting platforms"
            }
          />
          <ImageCard
            Icon={Image2}
            title1={"Effortless"}
            title2={"On-boarding"}
            content={
              "Downloadable and pre-built connectors for Accounting ERPs (Tally, Zoho Books) and External Drives for swift on-boarding and navigation"
            }
          />
          <ImageCard
            Icon={Image3}
            title1={"Empowering"}
            title2={"Flexibility"}
            content={
              "Dynamic Workflow Engine to customize your users' workflows, further enhancing accuracy on Accounting ERPs"
            }
          />
          <ImageCard
            Icon={Image4}
            title1={"Insightful"}
            title2={"Reporting"}
            content={
              "Dashboards deliver granular perspectives on the documents extracted, enabling you to grow with data-driven insights"
            }
          />
        </div>
        <br />
        <br />
        <p className="mt-7 max-w-96 text-stone-900 text-base font-normal leading-relaxed tracking-wide">
          Bottomline: Fexo is an intelligent, efficient, and remarkably accurate
          platform that transforms the cumbersome task of manual data entries
          from various documents into a seamless automated postings to
          accounting platforms.
        </p>
        <br />
        <br />
        <p className="max-w-96 text-stone-900 text-xl font-semibold leading-relaxed tracking-wide flex justify-center">
          Don't just take our word for it. Experience it!
        </p>
        <div className="flex w-full justify-center mt-10">
          <TrialBtn className={"w-64 h-16"} />
        </div>
      </div>
    );
}
export default Transform;