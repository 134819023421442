import React from "react";
import TrialBtn from "./TrialBtn";
import { QuickbooksIcon, TallyPrimeIcon, ZohoBooksIcon } from "../../images";

function WelcomeContent() {
  return (
    <div className="w-full h-full flex flex-row items-center relative">
      <div className="-mt-10 xl:mt-0">
        <header>
          <h1 className="text-[#2E0040] main-heading">
            Elevate Bookkeeping.
            <br />
            Smart Automation, Simplified.
            <br />
          </h1>
        </header>
        <div class="fexo-bg-image"></div>
        <body className="w-full">
          <div className="max-w-[35rem] mt-12 opacity-80 text-base md:text-3xl font-bold tracking-wide leading-loose">
            <p>Upload, Verify, and Post Seamlessly</p>
            <p>with 100% Accuracy to</p>
            <div className="flex gap-4 mt-2 md:mt-6 flex-col md:flex-row items-start">
              <TallyPrimeIcon className="w-28 h-8" />
              <QuickbooksIcon className="w-28 h-8 -ml-0.5" />
              <ZohoBooksIcon className="w-28 h-8 -ml-3" />
            </div>
          </div>
          <div className="flex justify-center sm:justify-start">
            <TrialBtn className="mt-12 md:mt-16 lg:mt-20 xl:mt-24 w-64 h-16 z-50" />
          </div>
        </body>
      </div>
    </div>
  );
}

export default WelcomeContent;
