import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import BookCallBtn from "../InvoiceProcessing/BookCallBtn";

const integrationData = [
  {
    title: "Access",
    items: ["Private Login on secured Cloud", "Private Cloud", "Secured FTP"],
  },
  {
    title: "Input",
    items: [
      "Read from emails",
      "Pick up from folders",
      "Via APIs",
      "Manual Uploads",
    ],
  },
  {
    title: "Output",
    items: ["Drop in folders", "Deliver via APIs", "Dashboard", "Reports"],
  },
];

const EffortlessIntegrations = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-4xl font-bold text-center p-5 text-[#2E0040]">
        Effortless Integrations
      </div>
      <div className="text-center text-[#1C1917] font-semibold text-xl max-w-3xl">
        Seamless integration with existing systems, offering flexible
      </div>
      <div className="text-center text-[#1C1917] font-semibold text-xl mb-8 max-w-3xl">
        Access, Input, and Output options
      </div>

      <div className="grid grid-cols-1 gap-10 md:grid-cols-3 md:gap-20 w-full md:justify-items-center">
        {integrationData.map((section, index) => (
          <div key={index} className="text-center">
            <h3 className="text-xl font-bold text-purple-700 mb-5">
              {section.title}
            </h3>

            {section.items.map((item, i) => (
              <div
                key={i}
                className="flex mx-5 md:mx-0 items-center mb-5 md:flex-row md:justify-start"
              >
                <CheckCircleOutline className="text-orange-400 mr-2 mb-2 md:mb-0" />
                <span>{item}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-3 mb-8 w-full">
        <BookCallBtn className=" w-64 h-16 z-50" text="Book a demo" />
      </div>
    </div>
  );
};

export default EffortlessIntegrations;
