import React from "react";
// import TryButton from "./TryButton";
// import BookCallBtn from "./BookCallBtn";
import TrialBtn from "./TrialBtn";
function GetStarted() {
  return (
    <div className="bottom-image">
      <h2 className="text-[#2E0040] sub-heading mb-5">Let's get started</h2>
      <p className="text-base font-normal tracking-wide">
        Embrace State-of-the-Art Accounting Automation
        <br />
        Enhance Efficiency with Swift and Precise Financial Entries
      </p>
      <div className="flex mt-8 md:w-96 justify-center">
        <TrialBtn className="w-64 h-14" />
      </div>
    </div>
  );
}

export default GetStarted;
