import React from "react";
import { Link } from "react-router-dom";

function GDPR() {
  return (
    <div ClassName="text-justify">
      <p className="text-stone-900 text-base font-normal ">
        Effective Date: 10th January 2024
        <br />
        <br />
        Privacy and security form the bedrock of OpenTurf's approach to product
        development and business. We consistently assess all our practices to
        enhance the protection of your information. In alignment with this
        commitment, we adhere to the General Data Protection Regulation ("GDPR")
        and assist OpenTurf's customers in achieving GDPR compliance.
        <br />
        <br />
        What is GDPR?
        <br />
        Effective May 25, 2018, the General Data Protection Regulation (GDPR)
        comes into force, expanding data privacy rights for European individuals
        and empowering them with greater control over their data. Companies
        handling the personal data of these European individuals are mandated to
        comply with a new set of relevant regulations.
        <br />
        <br />
        The GDPR stipulates specific requirements for these companies to meet,
        and outlines the rights that European individuals can exercise. For more
        details on the GDPR, refer to the European Union’s official website:{" "}
        <Link
          className="underline text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
          to="https://ec.europa.eu/info/law/law-topic/data-protection_en"
        >
          https://ec.europa.eu/info/law/law-topic/data-protection_en
        </Link>
        .
        <br />
        <br />
        Features Supporting GDPR Requirements: OpenTurf offers functionalities
        to assist you in fulfilling your data portability requirements under the
        GDPR. You can effortlessly request the permanent deletion of all your
        data by sending an email to info@openturf.in.
        <br />
        <br />
        If you have further questions, please reach out to info@openturf.in.
        <br />
        <br />
      </p>
    </div>
  );
}

export default GDPR;
