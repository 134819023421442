import React from "react";
import ContentInPoints from "../HomePageContent/ContentInPoints";

function Keybenefits() {
  const keybenefit_points = [
    [
      "Classify Documents",
      "Extract Pre-Defined Fields",
      "Summarize Documents",
      "Analyze Documents for Insights"
    ],
    [
      "Compare Documents for Anomalies",
      "Search Within Documents",
      "Converse with Documents",
      "Generate New Documents"
    ],
  ];
  return (
    <div >
      <div className="text-center text-4xl font-bold mb-5 text-[#2E0040]">
        Key Outcomes
      </div>
      <ContentInPoints rows={keybenefit_points} />
   
    </div>
  );
}

export default Keybenefits;
