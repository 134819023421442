import React from "react";
import TrialBtn from "../InvoiceProcessing/TrialBtn";
import { ZohoBooksIcon, FexoLogoIcon } from "../../images";
import { Link } from "react-router-dom";

function ZohoBooksContent() {
  return (
    <div className="pt-16 ">
        <body className="w-full">
        <div class = "text-stone-900 text-2xl font-[1000] flex justify-center">
            Fexo Integration with Zoho Books: Simplify Invoice and Bank Statement Automation
        </div>
        <div class = "text-stone-900 pt-6 font-[500] text-xl">
            <div class="mb-2 font-bold"> Seamless Integration of Fexo with Zoho Books<br/> </div>
            Simplify your financial automation by automating the extraction of and posting of invoices and bank statements directly into Zoho Books. 
            With Fexo's AI-powered platform, upload your financial documents, extract key data like transaction details and amounts, and effortlessly post them onto your Zoho Books account. <br/>
            <div class="mb-2 mt-2 font-bold">Eliminate manual data entry, reduce errors, and streamline your accounting workflow.<br/></div> Whether you're reconciling bank statements or processing invoices, Fexo ensures faster, more accurate bookkeeping within Zoho Books
        </div>
        
            <div className="flex gap-4 mt-2 md:mt-6 flex-col justify-center md:flex-row items-center">
            <div class="pt-4 ml-20"><FexoLogoIcon className="h-[180px] w-auto pt-4"/></div>
              <ZohoBooksIcon className="h-[120px] w-auto" />
            </div>
          <div className="flex justify-center">
            <TrialBtn className="mt-6 w-64 h-16 z-50" />
          </div>
          <div class = "text-stone-900 pt-6 font-[500] text-xl">
          <div class="mb-2"><b>Steps to integrate Fexo into your Zoho Books account</b></div>
            <div class="ml-2"><b>1. Log in to Fexo:</b> Start by signing into your Fexo account <br/></div>
           <div class="ml-2"> <b> 2. Configure Zoho Books as your destination:</b> Navigate to the Configuration Settings and select Zoho books as the output destination for your data<br/></div> 
           <div class="ml-2"><b> 3. Connect your Zoho Books account: </b>Sign in to your Zoho Books account to establish a secure connection</div><br/>
           <div class = "mb-2">For more detailed guidance, <Link 
        to="/zoho-books-integration-guide" 
        className="underline text-blue-500 hover:text-darkblue transition-colors duration-300"
      >click here</Link><br/></div>
           Once integrated, Fexo will automatically retrieve your vendor and bank information. Simply upload your invoices or bank statements, and with just a few clicks, post the extracted details directly into Zoho Books for seamless accounting.
        </div>
        </body>
      </div>
  );
}

export default ZohoBooksContent;
