import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "white", // change this to your preferred color
  borderRadius: "10px", // change this to your preferred border radius
  boxShadow: "none",
  "&.Mui-expanded": {
    margin: "auto",
  },
  "&:before": {
    display: "none",
  },
  "&.MuiAccordion-root:hover": {
    backgroundColor: "#f4f4f5", // change this to your preferred hover color
  },
}));

function RenderAccordionContent({
  name,
  expanded,
  handleChange,
  question,
  answer,
}) {
  return (
    <StyledAccordion expanded={expanded === name} onChange={handleChange(name)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${name}bh-content`}
        id={`${name}bh-header`}
      >
        <label className="text-gray-700 font-semibold text-lg">
          {question}
        </label>
      </AccordionSummary>
      <AccordionDetails className="text-stone-900 text-base font-normal tracking-wide">
        {answer}
      </AccordionDetails>
    </StyledAccordion>
  );
}

const faq_content = [
  {
    question: "What types of Documents does the platform support?",
    answer:
      "The platform supports documents like Invoice, PO, Bill of Quantities, Receipts and Bank Statement and extracts the relevant details from it  The platform accepts documents that are electronically generated PDFs or scanned PDF images.",
  },
  {
    question: "Is the platform deployed on my premises or on the cloud?",
    answer:
      "The platform is offered as a SAAS (Software as a Service) model. It is deployed in a fully secured cloud. Every enterprise will have their own unique logins, users, access to data with their specific integrations and have access to it 24x7x365. No user can ever access data of other users on the platform. We, as the service provider, will not have access to an enterprise’s specific data. You can check out our Privacy Policy for further details.",
  },
  {
    question: "Can I integrate the platform with other software?",
    answer:
      "Yes, the platform supports integration with 3rd party software. This integration enables a need-based connection between them, facilitating an auto update of the ledgers of the 3rd party software. Additionally, the integration is so easy that one can configure it themselves. Of course, we will be present during this process. Check out our policy regarding 3rd Party Subprocessors.",
  },
  {
    question:
      "Can the user verify the data before it is updated to the accounting ledger?",
    answer:
      "Yes, users get to view the extracted digital data on the platform. Users have the flexibility to edit, classify and add relevant details to the data before posting them to the ledgers of 3rd party software.",
  },
  // {
  //   question: "Is there a maker-checker concept on the platform?",
  //   answer:
  //     "Yes, there are two user roles – Initiator and Approver. The Initiator reviews the extracted digital data and sends it to an Approver. The ledger update happens after the approval from the Approver. The platform provides the flexibility to not configure an Approver if this is an unwanted step in an enterprise.",
  // },
  // {
  //   question: "Do the users of the platform require comprehensive training?",
  //   answer:
  //     "The platform is designed to make it intuitive for the users to use. The users are provided with the FAQs for their clarifications, if any. We are of the opinion that training may not be required. Of course, we are reachable for any assistance.",
  // },
  // {
  //   question:
  //     "What can the users do if the invoice extraction data is incorrect?",
  //   answer:
  //     "The platform has an accuracy rate of more than 98% for the extraction of the data from invoices. If there are minor errors in the extraction, the user can correct them instantly. If certain fields are not getting extracted, then the user can report the issue to us along with the specific invoice. We have stringent SLAs to ensure high customer satisfaction.",
  // },
  {
    question: "How can a user report an issue to the platform support team?",
    answer: `If a user is having a problem, send a mail to Fexo Support. Explain the issue and attach the invoice causing the problem. We'll look into it and get back to you with a resolution. It is an easy way to get help when you need it.`,
  },
  // {
  //   question: "What is the software release model for the platform?",
  //   answer: `We do a periodic release of the platform. Before the release to the production environment, the release is thoroughly tested in our environment. Thereafter, it is deployed in the production environment. This is done seamlessly without affecting any customer journeys. These releases are not chargeable.`,
  // },
  // {
  //   question: "Can I customize the platform to meet my specific needs?",
  //   answer: `We believe the current workflow would suffice for most of our customers. We also understand that every business has unique requirements. So, we are open to customization. Do reach out to us and we can explore further. In the interim, trying out the services provided by the platform can help in making the adoption decision easier.`,
  // },
  {
    question: "Is there a trial period available?",
    answer: `Yes, there is a commitment free look-in period of 30 days. After that, the enterprise is prompted to choose a price, payment plan and enter their card details to continue using the services further. During the commitment free period, an enterprise will get access to all the features of the platform. Besides, we will be available during the self-onboarding process and provide relevant support for the usage of the platform throughout this duration.`,
  },
  {
    question: "What is the pricing model for using the platform?",
    answer: `It is quite simple. Based on the number of pages that an enterprise intends to extract in a month, they can select the plan that is most suitable to them. The payment can be done monthly or annually. The platform will display the pages consumed for the specific duration in real time. The enterprise has the option to upgrade or downgrade from their current plan. The enterprise has the option to purchase a top-up on the current plan.`,
  },
  {
    question: "How do I get started?",
    answer: `After sign-up on the website, one is taken to the configuration page. There are 3 activities to be done - Configure the input sources, output destinations and the users of the service. The whole process should take 10-15 minutes. Of course, we will be present during this process to make sure that you are on-boarded successfully.`,
  },
  // {
  //   question: "How do my other users get started?",
  //   answer: `The configured users will be notified via email. Once they click on the link in the email, they would be requested to enter their credentials to start using the platform. Thereafter, they will get access to the intuitive dashboard from where they can navigate on the platform and access allowed services.`,
  // },
];

function FAQcontent() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <h2 className="text-[#2E0040] sub-heading mb-5">
        Frequently Asked Questions
      </h2>
      <div>
        {faq_content.map((faq, i) => (
          <RenderAccordionContent
            key={i}
            name={`pannel-${i}`}
            expanded={expanded}
            handleChange={handleChange}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
    </div>
  );
}

export default FAQcontent;
