export const urlBase = process.env.REACT_APP_API_URL;
export const chatBase = process.env.REACT_APP_API_CHAT_URL;

export const API_ROUTES = {
  subscripe: urlBase + "/v1/subscribe/newsletter",
  OCR: {
    extract: urlBase + "/v1/extract/playground",
    getResult: urlBase + "/v1/extract/result",
    getFile: urlBase + "/v1/extract/getJobFile",
    getFields: urlBase + "/v1/extract/jobFields",
  },
  Chinese: {
    extract: urlBase + "/v1/extract/chineseInvoice",
    getResult: urlBase + "/v1/extract/chineseInvoice",
  },
  kleeto: {
    extract: urlBase + "/v1/kleeto/extract",
    getResult: urlBase + "/v1/kleeto/result",
  },
  paymentReceipt: urlBase + "/v1/extract/paymentReceipt",
  metadata: {
    getDocTypes: urlBase + "/v1/metadata/docTypes",
    getFormFields: urlBase + "/v1/metadata/formFields",
  },
  docQuery: {
    upload: urlBase + "/v1/doc-query",
    get: urlBase + "/v1/doc-query/",
    chat: chatBase + "/v1/doc-query/chat/",
  },
};

export default function getURL(urlKey) {
  return API_ROUTES[urlKey];
}
