import React from "react";
import ContentInPoints from "../ContentInPoints";
import TrialBtn from "./TrialBtn";
// import TryButton from "./TryButton";
// import TrialBtn from "./TrialBtn";
function Keybenefits() {
  const keybenefit_points = [
    [
      "Cuts data entry time by 95%",
      "Removes human errors and prevents fraud",
      "Enhances productivity, generates additional revenues",
    ],
    [
      "Instant ROI - Within 1st month of usage",
      "Smart workflow engine intelligently posts entries",
      "Single source of truth, integrate-able with Accounting platforms",
    ],
  ];
  return (
    <div>
      <h2 className="text-[#2E0040] mb-5 text-3xl font-bold tracking-wide">
        Key Benefits
      </h2>
      <ContentInPoints rows={keybenefit_points} />
      <div className="flex w-full justify-center mt-10">
        <TrialBtn className={"w-64 h-16"} />
      </div>
    </div>
  );
}

export default Keybenefits;
