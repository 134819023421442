import React from "react";
import { PopupButton } from "react-calendly";
import { DEMO_LINK } from "../../config";

function BookCallBtn({ className, text }) {
  return (
    <PopupButton
      // url="https://calendly.com/sriramjeyabharathi/xt-invoice-automation"
      // url="https://calendly.com/kaustubhkashyap/xt-invoice-automation-platform"
      url={DEMO_LINK}
      rootElement={document.getElementById("root")}
      className={`${className} bg-orange-500 rounded-lg shadow-xl text-white ${
        text ? "text-xl" : "text-lg"
      }`}
      text={<span>{text ? text : "Book a call"}</span>}
    />
  );
}

export default BookCallBtn;
