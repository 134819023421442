import { CheckCircleOutline } from "@mui/icons-material";
import React from "react";

function ContentInPoints({ rows }) {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="grid lg:grid-cols-2 lg:gap-x-40 gap-y-4">
        {rows.map((row, i) => {
          return (
            <div className="w-full" key={i}>
              {row.map((column, index) => {
                return (
                  <div key={index} className="flex items-center w-full h-12 mb-3">
                    <CheckCircleOutline className="text-orange-400 mr-2" />
                    <div className="text-[#1C1917] text-base leading-tight">
                      {column}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ContentInPoints;
