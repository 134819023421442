import React from "react";
function AboutXT() {
  return (
    <div>
      <h2 className="text-[#2E0040] sub-heading mb-8">
        About Fexo Financial Accounting Automation
      </h2>
      <div className="mb-14">
        <p className="text-stone-900 text-base font-normal  leading-relaxed tracking-wide">
          Fexo unlocks the power of automated data extraction from{" "}
          <span className="font-bold">PDFs, Scanned PDFs, Excels</span> and{" "}
          <span className="font-bold">Images</span>, at scale.
          <br />
          <br />
        </p>

        <p className="text-stone-900 text-base font-normal leading-relaxed tracking-wide">
          It streamlines end-to-end financial accounting process - Automated
          data extraction from unstructured documents to posting on financial
          accounting platforms, leading up to 80% productivity enhancements.
          <br />
          <br />
        </p>
        <p className="text-stone-900 text-base font-normal leading-relaxed tracking-wide">
          Upload any format of <span className="font-bold">Invoices</span> and{" "}
          <span className="font-bold">Bank Statements</span>. Classify them as
          per your ledgers and post them on{" "}
          <span className="font-bold">Tally Prime, Zoho Books</span> and{" "}
          <span className="font-bold">Quickbooks</span> in real time.
        </p>
      </div>
    </div>
  );
}

export default AboutXT;
