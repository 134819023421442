import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Pagination, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../../components/Footer";
import NavbarContent from "../../components/Navbar/NavbarContent";
import { Element } from "react-scroll";
import getURL from "../../routes/api-urls";
import MiddleContainer from "../../container/MiddleContainer";
import SnackAlert from "../../components/SnackAlert";
import useSnack from "../../hooks/useSnack";
import "../../styles/extractedPage.css";
import FoundUsefull from "../../components/Playground/FoundUsefull";
import { convertJSONToCSV } from "../../utils/jsonToCSV";
import useMenu from "../../hooks/useMenu";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ExportButton = ({ data }) => {
  const { menuRef, menuClass, toggleMenu } = useMenu();

  const handleExport = () => {
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `XT Result.json`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleExportAsCSV = () => {
    const csv = convertJSONToCSV(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const csvURL = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "invoice.csv";
    link.click();

    URL.revokeObjectURL(csvURL);
  };

  return (
    <div
      disabled={!data}
      className="relative text-center cursor-pointer w-40 h-12 bg-[#6A2588] rounded-lg shadow-xl text-white text-lg p-[10px] disabled:opacity-30"
      onClick={toggleMenu}
    >
      <p>Export as</p>
      <div
        className={`${menuClass} cursor-default absolute flex flex-col left-0 top-12 w-40 gap-2 items-start p-2.5 border border-purple-900 bg-zinc-100 shadow-lg text-black rounded-md`}
        ref={menuRef}
      >
        <button
          className="border w-full p-1 text-white bg-[#6A2588] rounded-md"
          onClick={() => handleExport()}
        >
          JSON
        </button>
        <button
          className="border w-full p-1 text-white bg-[#6A2588] rounded-md"
          onClick={() => handleExportAsCSV()}
        >
          CSV
        </button>
      </div>
    </div>
  );
};

function ChineseExtractionPage() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const firstUpdate = useRef(true);

  const [extractedData, setExtractedData] = useState(false);
  const [jobFile, setJobFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const { showMessage, getProps } = useSnack();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getJobDetails = () => {
      axios
        .get(`${getURL("Chinese").getResult}?jobId=${jobId}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN_CHINESE}`,
          },
        })
        .then(({ data }) => {
          if (data.result?.isProcessing) {
            setTimeout(() => getJobDetails(), 2000);
          } else {
            setExtractedData(data.result);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          showMessage("error", error.message);
        });
    };

    const getJobFile = (count = 0) => {
      axios
        .get(`${getURL("OCR").getFile}/${jobId}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN_CHINESE}`,
          },
        })
        .then(({ data }) => {
          setJobFile(data.result);
        })
        .catch((error) => {
          console.log("error: ", error);
          if (count < 5) {
            setTimeout(() => getJobFile(count + 1), 1000);
          }
        });
    };

    if (firstUpdate.current) {
      getJobDetails();
      getJobFile();
      firstUpdate.current = false;
    }
  }, [jobId]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <NavbarContent>
        <div className="flex space-x-8 items-center">
          <ExportButton data={extractedData.data} />
          <button
            className="w-40 h-12 bg-[#6A2588] rounded-lg shadow-xl text-white text-lg"
            onClick={() => navigate("/chinese")}
          >
            Back
          </button>
        </div>
      </NavbarContent>
      <SnackAlert {...getProps} />
      <MiddleContainer bgColor={"bg-zinc-100 pt-28"}>
        <div className="flex justify-between">
          <h1 className="text-[#2E0040] sub-heading font-serif">
            {`Experience Fexo Invoice Automation Platform(Chin.)`}
          </h1>
        </div>
        <div className="flex justify-start space-x-10 mt-10">
          <div className=" flex justify-end">
            <div>
              <div
                className="border"
                style={{
                  maxWidth: "650px",
                  maxHeight: "650px",
                  overflowY: "scroll",
                }}
              >
                {!jobFile ? (
                  <Skeleton
                    variant="rectangular"
                    width={"650px"}
                    height={"650px"}
                  />
                ) : (
                  <>
                    <Document
                      file={jobFile.docImage}
                      loading={
                        <Skeleton
                          variant="rectangular"
                          width={"650px"}
                          height={"650px"}
                        />
                      }
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={() => {
                        console.log("PDF Load Error!");
                      }}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </>
                )}
              </div>
              <div className="flex justify-center">
                <Pagination
                  className="mt-4"
                  count={numPages}
                  onChange={(event, value) => setPageNumber(value)}
                  page={pageNumber}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="form-container">
              <div className="field-container">
                <h5 className="field-label">Invoice Number</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="invoiceNumber" className="field-content">
                    {extractedData.data.formData?.INVOICE_NUMBER || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Invoice Date</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="invoiceDate" className="field-content">
                    {extractedData.data.formData?.INVOICE_DATE || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Invoice Total</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="invoiceTotal" className="field-content">
                    {extractedData.data.formData?.INVOICE_TOTAL || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Invoice Currency</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="paymentTerms" className="field-content">
                    {extractedData.data.formData?.INVOICE_CURRENCY || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Tax Rate</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="paymentTerms" className="field-content">
                    {extractedData.data.formData?.TAX_RATE || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Tax Amount</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="paymentTerms" className="field-content">
                    {extractedData.data.formData?.TAX_AMOUNT || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Supplier Name</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="vendorName" className="field-content">
                    {extractedData.data.sellerInfo.name || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Supplier Address</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="vendorAdd" className="field-content">
                    {extractedData.data.sellerInfo.address || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Supplier Country</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="vendorName" className="field-content">
                    {extractedData.data.sellerInfo.country || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Buyer Name</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="vendorName" className="field-content">
                    {extractedData.data.buyerInfo.name || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Buyer Address</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="vendorAdd" className="field-content">
                    {extractedData.data.buyerInfo.address || ""}
                  </label>
                )}
              </div>

              <div className="field-container">
                <h5 className="field-label">Buyer Country</h5>
                {!extractedData ? (
                  <Skeleton
                    variant="rectangular"
                    width={"8rem"}
                    height={"2rem"}
                  />
                ) : (
                  <label id="vendorName" className="field-content">
                    {extractedData.data.buyerInfo.country || ""}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
        <Element name="table-content">
          <div className="mt-8">
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {extractedData?.data?.goodsInformation[0].map(
                      (colmn, i) => (
                        <StyledTableCell key={i}>{colmn}</StyledTableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extractedData?.data?.goodsInformation.map((row, i) => {
                    if (
                      row.length === 0 ||
                      row.filter((x) => x !== "").length === 0 ||
                      i === 0
                    ) {
                      return null;
                    }
                    return (
                      <StyledTableRow key={i}>
                        {row.map((item, j) => (
                          <StyledTableCell key={j} component="th" scope="row">
                            {item}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Element>
      </MiddleContainer>
      <MiddleContainer bgColor={"bg-white"}>
        <FoundUsefull />
      </MiddleContainer>
      <Element name="contact">
        <MiddleContainer bgColor={"bg-[#2E0040]"}>
          <Footer />
        </MiddleContainer>
      </Element>
    </>
  );
}

export default ChineseExtractionPage;
