import React from "react";
import "../../styles/contentStyles.css";
import TrialBtn from "./TrialBtn";
import { YOUTUBE_CODE } from "../../config";

function AIContent() {
  return (
    <div>
      <div>
        <div className=" lg:col-span-1">
          <h2 className="text-[#2E0040] sub-heading">
            Automate Financial Bookkeeping
          </h2>
        </div>

        <p className="mt-7 max-w-96 text-base font-normal leading-relaxed tracking-wide">
          Say goodbye to manual data entry headaches!
          <br />
          Fexo Bookkeeping Automation makes processing seamless, efficient and
          error-free.
        </p>
        <div className="w-full rounded-lg bg-white shadow-xl p-4">
          <iframe
            href={`//www.youtube.com/watch?v=${YOUTUBE_CODE}`}
            data-videoid={YOUTUBE_CODE}
            class="embedded-video-large"
            frameborder="0"
            allowfullscreen="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            alt="Demo Video"
            title="Demo Video"
            src={`https://www.youtube.com/embed/${YOUTUBE_CODE}?autoplay=0&amp;cc_lang_pref=en&amp;cc_load_policy=1&amp;controls=0&amp;rel=0&amp;hl=en&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fsupport.google.com&amp;widgetid=1`}
            className="w-full h-[350px] rounded-lg lg:h-[450px]"
          />
        </div>
        <div className="flex justify-center mt-10 w-full">
          <TrialBtn className={"w-64 h-16"} />
        </div>
      </div>
    </div>
  );
}

export default AIContent;
