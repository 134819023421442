import React, { useState } from "react";
import ArticleComponent from "./ArticleComponent";
import articleDetails from "../../collections/article-description.json";
import { PreviousIcon, NextIcon } from "../../images";

const Article = () => {
  const articles = articleDetails.articleDescription;
  const [currentIndex, setCurrentIndex] = useState(0);

  const articlesPerPage = window.innerWidth < 640 ? 1 : 3; 
  const currentArticles = articles.slice(
    currentIndex,
    currentIndex + articlesPerPage
  );

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - articlesPerPage);
    }
  };

  const handleNext = () => {
    if (currentIndex + articlesPerPage < articles.length) {
      setCurrentIndex(currentIndex + articlesPerPage);
    }
  };

  return (
    <div className="container mx-auto">
      <div className="text-center text-4xl font-bold mb-10 text-[#2E0040]">
        White Papers and Articles
      </div>

      <div className="flex flex-wrap justify-between gap-6 space-x-6 sm:space-x-0">
        {currentArticles.map((article) => (
          <div className="flex-1 sm:w-full" key={article.id}>
            <ArticleComponent
              title={article.title}
              date={article.date}
              content={article.content}
              buttonText={article.buttonText}
              id={article.id}
              className="h-full"
            />
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-10">
        <button
          onClick={handlePrevious}
          disabled={currentIndex === 0}
          className={`flex items-center text-lg text-stone-900 ${
            currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <PreviousIcon className="mr-2" />
          Previous
        </button>

        <button
          onClick={handleNext}
          disabled={currentIndex + articlesPerPage >= articles.length}
          className={`flex items-center text-lg text-stone-900 ${
            currentIndex + articlesPerPage >= articles.length
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
        >
          Next
          <NextIcon className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default Article;
