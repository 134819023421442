import React from "react";
import { RightArrowIcon } from "../../images";
import { useNavigate } from "react-router-dom";

const ArticleComponent = ({ title, date, content, buttonText, id }) => {
  const navigate = useNavigate();
  const handleReadMoreClick = () => {
    navigate("/article/" + id, { state: { articleId: id } });
  };

  return (
    <div className="flex flex-col justify-between w-full max-w-lg p-6 border border-gray-300 rounded-md shadow-md h-full">
      <div>
        <div className="text-xl font-bold mb-1  text-[#2E0040]">{title}</div>
        <p className="text-gray-500 text-sm mb-4">Posted {date}</p>
        <div className="text-base text-[#1C1917] mb-4">{content}</div>
      </div>
      <div className="mt-auto">
        <button
          className="flex items-center bg-black text-white px-5 py-4 rounded-md text-sm font-semibold"
          onClick={handleReadMoreClick}
        >
          {buttonText}
          <span className="ml-2">
            <RightArrowIcon className="w-8 h-8" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ArticleComponent;
