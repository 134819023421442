import React, { useEffect, useState } from "react";
import NavbarContent from "../components/Navbar/NavbarContent";
import { Element, Link } from "react-scroll";
import MainContainer from "../container/MainContainer";
import WelcomeContent from "../components/InvoiceProcessing/WelcomeContent";
import AIContent from "../components/InvoiceProcessing/AIContent";
import MiddleContainer from "../container/MiddleContainer";
import Keybenefits from "../components/InvoiceProcessing/Keybenefits";
import AboutXT from "../components/InvoiceProcessing/AboutXT";
import Transform from "../components/InvoiceProcessing/Transform";
import Features from "../components/InvoiceProcessing/Features";
import GetStarted from "../components/InvoiceProcessing/GetStarted";
import Footer from "../components/Footer";
import { ArrowIcon } from "../images";
import Plans from "../components/InvoiceProcessing/Plans";
import SigninBtn from "../components/InvoiceProcessing/SigninBtn";
import FAQcontent from "../components/InvoiceProcessing/FAQcontent";
import HappyClients from "../components/InvoiceProcessing/HappyClients";

const styles = {
  background_colors: {
    white: "bg-white",
    zinc: "bg-zinc-100",
    blue: "bg-[#6A2588]",
  },
};

function BookKeeping() {
  const [upBtnClass, setUpBtnClass] = useState(
    "fill-transparent cursor-default"
  );

  useEffect(() => {
    const handleScroll = () => {
      const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      if (!window.scrollY) {
        setUpBtnClass("fill-transparent cursor-default");
      } else if (window.scrollY < maxScroll - 400) {
        setUpBtnClass("fill-[#6A2588] cursor-pointer");
      } else {
        setUpBtnClass("fill-white cursor-pointer");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const gotoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="fixed right-4 bottom-4 w-12 h-12 z-50">
        <ArrowIcon
          disabled={true}
          className={`-rotate-90 w-10 h-10 ${upBtnClass}`}
          onClick={() => gotoTop()}
        />
      </div>
      <NavbarContent>
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              to="About"
              smooth={true}
              duration={500}
              spy={true}
              offset={-100}
              className="nav-link"
            >
              About
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="Features"
              smooth={true}
              duration={500}
              spy={true}
              offset={-50}
              className="nav-link"
            >
              Features
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="Plans"
              smooth={true}
              duration={500}
              spy={true}
              offset={-50}
              className="nav-link"
            >
              Plans
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="FAQs"
              smooth={true}
              duration={500}
              spy={true}
              offset={-50}
              className="nav-link"
            >
              FAQs
            </Link>
          </li>
          <li className="nav-item">
            <SigninBtn className={"w-40 h-12"} />
          </li>
        </ul>
      </NavbarContent>

      <MainContainer>
        <WelcomeContent />
      </MainContainer>

      <MiddleContainer bgColor={styles.background_colors.white}>
        <AIContent />
      </MiddleContainer>

      <Element name="About">
        <MiddleContainer bgColor={styles.background_colors.zinc}>
          <AboutXT />
          <Keybenefits />
        </MiddleContainer>
      </Element>

      <MiddleContainer bgColor={styles.background_colors.white}>
        <HappyClients />
      </MiddleContainer>

      <MiddleContainer bgColor={styles.background_colors.zinc}>
        <Transform />
      </MiddleContainer>

      <Element name="Features">
        <MiddleContainer bgColor={styles.background_colors.white}>
          <Features />
        </MiddleContainer>
      </Element>

      <Element name="Plans">
        <MiddleContainer bgColor={styles.background_colors.zinc}>
          <Plans />
        </MiddleContainer>
      </Element>

      <Element name="FAQs">
        <MiddleContainer bgColor={styles.background_colors.white}>
          <FAQcontent />
        </MiddleContainer>
      </Element>

      <MiddleContainer bgColor={styles.background_colors.zinc}>
        <GetStarted />
      </MiddleContainer>

      <Element name="Contact">
        <MiddleContainer bgColor={styles.background_colors.blue}>
          <Footer />
        </MiddleContainer>
      </Element>
    </div>
  );
}

export default BookKeeping;
