import React from "react";

function MiddleContainer({ bgColor, children }) {
  return (
    <div className={`w-full h-full py-12 main-container ${bgColor}`}>
      <div className="middle-container h-full">{children}</div>
    </div>
  );
}

export default MiddleContainer;
