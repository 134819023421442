import React, { forwardRef } from "react";

const Label = forwardRef(
  ({ check, radio, disabled, className, children, ...rest }, ref) => {
    const baseStyles = "block text-sm text-gray-700";
    const checkStyles = "inline-flex items-center";
    const disabledStyles = "opacity-50 cursor-not-allowed";

    const classes = [
      baseStyles,
      check && checkStyles,
      disabled && disabledStyles,
      className,
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <label ref={ref} className={classes} {...rest}>
        {children}
      </label>
    );
  }
);

export default Label;
