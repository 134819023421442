import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import BookCallBtn from "./BookCallBtn";
import TrialBtn from "./TrialBtn";
import TryButton from "./TryButton";
import "../../styles/plan.css";

const Plans = () => {
  return (
    <>
      <div>
        <h1 className="text-[#2E0040] text-5xl mb-5 font-bold">Our Plans</h1>
      </div>
      {/* <div className="text-center font-semibold text-[#000] text-2xl mt-2 mb-8">
        <div>
          <h1 classname="leading-[3rem]">
            Choose a plan that's right for you and your team
          </h1>
        </div>
      </div> */}
      <div className="grid grid-cols-3 text-center place-items-center m-auto text-[#000] max-lg:grid-cols-1 gap-x-20 2xl:gap-x-10 gap-y-16 md:gap-y-20">
        <div className="w-[21rem] mx-4 justify-center border border-gray-100 rounded-lg shadow-lg bg-white py-2">
          <div className="my-3">
            <div className>
              <span className="text-2xl leading-10 text-[#6A2588] font-semibold">
                Experience Center
              </span>
            </div>
            <div className="mx-8 mt-8">
              <div className="grid lg:grid-cols lg:gap-x-5 w-full justify-center my-3">
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  100 Pages / month
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" /> Daily
                  Access Limit
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" /> Upload
                  Invoices
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" /> Export
                  to CSV and JSON
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" /> Access
                  to Support desk
                </div>
              </div>
              <TryButton className={"w-[70%] h-10 mt-2 mb-4"} />
            </div>

            <span className="text-sm font-bold text-stone-900 opacity-60">
              No Sign-up required
            </span>
          </div>
        </div>
        <div className="w-[21rem] h-[30rem] mx-4 justify-center border-2 border-orange-400 rounded-lg shadow-lg bg-white">
          <div className="w-full h-10 bg-orange-400 flex text-center items-center justify-center">
            <label className="text-white font-bold text-lg">Recommended</label>
          </div>
          <div className="my-3">
            <div className>
              <span className="text-2xl leading-10 text-[#6A2588] font-semibold">
                Standard Plan
              </span>
            </div>
            <div className="mx-8 mt-8">
              <div className="grid lg:grid-cols lg:gap-x-5 w-full justify-center my-3">
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  Upto 5000 Pages / month
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" /> 30
                  days Free Access
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  Automated Import & Export
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  Access to all features
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  Access to Support desk
                </div>
              </div>
              <TrialBtn className="w-[75%] h-12 mt-1 mb-4 text-lg button-highlight" />
            </div>
            <span className="text-sm font-bold text-stone-900 opacity-60">
              No Card required
            </span>
          </div>
        </div>
        <div className="w-[21rem] mt-2 mb-4 justify-center border border-gray-100 rounded-lg shadow-lg bg-white py-3">
          <div className="my-3">
            <div className="">
              <span className="text-2xl leading-10 text-[#6A2588] font-semibold">
                Enterprise Plan
              </span>
            </div>
            <div className="mx-8 mt-8">
              <div className="grid lg:grid-cols lg:gap-x-5 w-full justify-center my-3">
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  &gt; 5000 Pages / month
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  Automated Import & Export
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" /> Access
                  to all features
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  Customization
                </div>
                <div className="flex text-base justify-start items-center text-start leading-10">
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  Access to Support desk
                </div>
              </div>
              <BookCallBtn className={"w-[70%] h-10 mt-2 mb-4"} />
            </div>
            <span className="text-sm font-bold text-stone-900 opacity-60 ">
              No Commitment required
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
