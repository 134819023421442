import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";
//import { YOUTUBE_HOMEPAGE } from "../config";
import BookCallBtn from "../components/InvoiceProcessing/BookCallBtn";

const features = [
  {
    title: "Customer Support",
    description:
      "Empower customer support teams by converting complex documents into easy-to-read formats for faster query resolution",
    keyFeatures: [
      "Converts PDFs and other formats into simplified, easy-to-read 'read me' files",
      "Intelligent document summarization for quick access to key information",
      "Supports a wide variety of document types, improving the customer service experience",
    ],
  },
];

function CustomerSupport() {
  return (
    <div className="text-justify ">
      <div className="text-stone-900 text-4xl font-bold text-center ">
        Customer Support
      </div>
      {features.map((feature, index) => (
        <div key={index} className="main-container">
          <div className="text-stone-900 mt-7 text-lg font-normal">
            {feature.description}
          </div>
          <div className=" font-medium mt-5 text-xl">Key Features</div>
          {feature.keyFeatures.map((item, idx) => (
            <div className="flex items-center mt-7" key={idx}>
              <CheckCircleOutline className="text-orange-400 mr-3" />
              <span className="text-lg">{item}</span>
            </div>
          ))}

          {/* <div className="w-full rounded-lg bg-white shadow-xl p-5 mt-3 md:mt-10">
            <iframe
              frameBorder="0"
              allowFullScreen="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title={`Demo Video - ${feature.title}`}
              src={`https://www.youtube.com/embed/${YOUTUBE_HOMEPAGE}?autoplay=0&amp;cc_lang_pref=en&amp;cc_load_policy=1&amp;controls=0&amp;rel=0&amp;hl=en&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fsupport.google.com&amp;widgetid=1`}
              className="w-full h-[300px] rounded-lg lg:h-[450px]"
            />
          </div> */}
          <div className="flex justify-center mt-10 w-full ">
            <BookCallBtn className="w-64 h-16 z-50" text="Book a demo" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default CustomerSupport;
