import axios from "axios";
import getURL from "../routes/api-urls";

const getDocTypes = () => {
  return axios.get(getURL("metadata").getDocTypes).then(({ data }) => data);
};

const getFormFields = (docType) => {
  return axios
    .get(getURL("metadata").getFormFields + "/" + docType)
    .then(({ data }) => data);
};

export const metadatarService = {
  getDocTypes,
  getFormFields,
};
