import React from "react";
import { useNavigate } from "react-router-dom";

function TryButton({ className }) {
  const navigate = useNavigate();

  return (
    <button
      className={`${className} bg-orange-500 rounded-lg shadow-xl text-white text-lg`}
      onClick={() => navigate("/playground")}
    >
      Try it now
    </button>
  );
}

export default TryButton;
