import React, { useEffect, useState } from "react";
import NavbarContent from "../Navbar/NavbarContent";
import { Element, Link } from "react-scroll";
import MiddleContainer from "../../container/MiddleContainer";
import Footer from "../Footer";
import { ArrowIcon } from "../../images";
import ZohoBooksIntegrationGuideContent from "./ZohoBooksIntegrationGuideContent";

const styles = {
  background_colors: {
    white: "bg-white",
    zinc: "bg-zinc-100",
    blue: "bg-[#6A2588]",
  },
};

function ZohoBooksIntegrationGuide() {
  const [upBtnClass, setUpBtnClass] = useState(
    "fill-transparent cursor-default"
  );

  useEffect(() => {
    const handleScroll = () => {
      const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      if (!window.scrollY) {
        setUpBtnClass("fill-transparent cursor-default");
      } else if (window.scrollY < maxScroll - 400) {
        setUpBtnClass("fill-[#6A2588] cursor-pointer");
      } else {
        setUpBtnClass("fill-white cursor-pointer");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const gotoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="fixed right-4 bottom-4 w-12 h-12 z-50">
        <ArrowIcon
          disabled={true}
          className={`-rotate-90 w-10 h-10 ${upBtnClass}`}
          onClick={() => gotoTop()}
        />
      </div>
      <NavbarContent/>

      <MiddleContainer bgColor={styles.background_colors.white}>
        <ZohoBooksIntegrationGuideContent/>
      </MiddleContainer>

      <Element name="Contact">
        <MiddleContainer bgColor={styles.background_colors.blue}>
          <Footer />
        </MiddleContainer>
      </Element>

     
    </div>
  );
}

export default ZohoBooksIntegrationGuide;
