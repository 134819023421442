import React from "react";

function Subprocessors() {
  return (
    <div className="mb-14">
      <p className="text-stone-900 text-base font-normal  leading-relaxed tracking-wide">
        Effective Date: 10th January 2024
        <br />
        <br />
        OpenTurf presently relies on third-party subprocessors to deliver
        essential infrastructure services and customer support. Before involving
        any third-party subprocessor, OpenTurf assesses their privacy, security,
        and confidentiality protocols and formalizes an agreement to enforce
        relevant obligations.
        <br />
        <br /> OpenTurf engages with the following subprocessors to host data,
        provide support and for other accessory services on Fexo.
        <br />
        <br />
        <table>
          <tr>
            <th>Subprocessor Name</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td>Analytics</td>
          </tr>
          <tr>
            <td>Calendly</td>
            <td>Customer support services</td>
          </tr>
          <tr>
            <td>Amazon Web Services</td>
            <td>Cloud infrastructure</td>
          </tr>
          <tr>
            <td>Google Cloud Services</td>
            <td>Cloud infrastructure</td>
          </tr>
          <tr>
            <td>Microsoft Azure</td>
            <td>Cloud infrastructure</td>
          </tr>
          <tr>
            <td>OpenAI</td>
            <td>Parser</td>
          </tr>
          <tr>
            <td>Tally</td>
            <td>Integration</td>
          </tr>
          <tr>
            <td>Zoho</td>
            <td>Integration</td>
          </tr>
          <tr>
            <td>Slack</td>
            <td>Internal Communication</td>
          </tr>
        </table>
      </p>
    </div>
  );
}

export default Subprocessors;
