import React from "react";
import { DownloadIcon, FileIcon } from "../images";

function SampleInvoiceCard() {
  const sampleFiles = [
    "Simple Invoice.pdf",
    "Complex Invoice.pdf",
    "Scanned Document.pdf",
  ];

  const handleDownloadSample = async (id) => {
    const fileName = sampleFiles[id];
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + fileName;
    link.download = fileName;
    link.click();
  };

  return (
    <div className="bg-zinc-100 p-6 w-full">
      <h2>
        <span className="text-stone-900 text-2xl font-normal">
          Sample Documents:
          <br />
        </span>
        <span className="text-stone-900 text-base font-normal">
          If you want to explore without uploading your own documents, use our
          sample document files to experience FEXO.
        </span>
      </h2>
      <div className="w-full items-center space-y-2 mt-5">
        {sampleFiles.map((file, i) => {
          return (
            <div
              key={i}
              className="flex mx-auto py-8 px-8 border rounded-lg w-[350px] h-14 bg-white justify-between items-center"
            >
              <FileIcon />
              <p>{file}</p>
              <DownloadIcon
                className="cursor-pointer"
                onClick={() => handleDownloadSample(i)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SampleInvoiceCard;
