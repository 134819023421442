export const form_types = {
  table: "Table",
  form: "Form",
};

export const field_types = {
  select: "select",
  text: "text",
  number: "number",
  date: "date",
  textarea: "textarea",
  email: "email",
  checkbox: "checkbox",
  password: "password",
  radio: "radio",
  file: "file",
  range: "range",
  color: "color",
  daterange: "daterange",
  amount: "amount",
  custom_ref_pop: "custom_ref_pop",
  custom_status: "custom_status",
};
