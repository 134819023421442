import React from "react";
import NavbarContent from "../Navbar/NavbarContent";
import { useNavigate } from "react-router-dom";
import MiddleContainer from "../../container/MiddleContainer";
import Footer from "../Footer";

function MobileViewWarning() {
  const navigate = useNavigate();

  return (
    <div>
      <NavbarContent />
      <MiddleContainer bgColor={"bg-zinc-100 pt-24"}>
        <h1 className="text-2xl font-bold mb-4">
          This page cannot be accessed on mobile devices
        </h1>
        <p className="text-gray-600">
          Please switch to a desktop view to access this content.
        </p>
        <button
          className="mt-5 text-center text-white text-base font-bold leading-relaxed tracking-wide w-32 h-12 rounded-lg shadow bg-[#6A2588] p-2.5"
          onClick={() => navigate("/")}
        >
          Home
        </button>
      </MiddleContainer>
      <MiddleContainer bgColor={"bg-[#2E0040]"}>
        <Footer />
      </MiddleContainer>
    </div>
  );
}

export default MobileViewWarning;
