import React from "react";
import BookCallBtn from "../InvoiceProcessing/BookCallBtn";

function FoundUsefull() {
  return (
    <div className="bottom-image-2">
      <h2 className="text-[#2E0040] sub-heading mb-5">
        Found it useful!
      </h2>
      <p className="opacity-80 text-stone-900 text-base font-normal tracking-wide">
        Let's help you deploy it.
      </p>
      <BookCallBtn className={"mt-8 w-56 h-14"} />
    </div>
  );
}

export default FoundUsefull;
