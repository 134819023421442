import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

function RenderContent({ title, arrayOfText }) {
  return (
    <div className="mt-2">
      <label className="text-lg font-semibold">{title}</label>
      <ul className="text-base list-disc mx-6">
        {arrayOfText.map((text, i) => (
          <li key={i}>{text}</li>
        ))}
      </ul>
    </div>
  );
}

function RenderContentHeading({ title, arrayOfText, arrayOfHeader }) {
  return (
    <div className="mt-2">
      <label className="text-lg font-semibold">{title}</label>
      <ul className="text-base list-disc mx-6">
        {arrayOfText.map((text, i) => (
          <li key={i}>
            <p className="font-semibold">
              {arrayOfHeader[i]}
              <span className="font-normal ml-1">{text}</span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

function PrivacyPolicy({ open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <label className="text-[#2E0040] sub-heading">
          Privacy Policy
        </label>
        <div className="text-base p-2 w-full overflow-y-auto h-[94%]">
          <header className="text-2xl font-bold">
            Privacy Statement for using XT services on OpenTurf Technologies
            Private Limited
          </header>
          <label className="">Effective Date: 10th January 2024</label>
          <div className="mt-2">
            <label className="text-lg font-semibold">1. Overview:</label>
            <div className="text-sm mx-6">
              OpenTurf Technologies Private Limited is committed to protecting
              the privacy and security of your information. This Privacy
              Statement explains how we collect, use, disclose, and safeguard
              your data when you use our FEXO platform.
            </div>
          </div>
          <RenderContentHeading
            title={"2. Information We Collect:"}
            arrayOfHeader={[
              "Account Information:",
              "Usage Data:",
              "Customer Support:",
            ]}
            arrayOfText={[
              "We collect and store information you provide when creating an account, including company details, user names, and contact information.",
              "We may collect data on how you interact with our platform, including log data, IP addresses, and browser information.",
              "When you contact our customer support, we may collect information about your inquiries and our responses.",
            ]}
          />

          <RenderContentHeading
            title={"3. How We Use Your Information:"}
            arrayOfHeader={[
              "Service Delivery:",
              "Communication:",
              "Customer Support:",
            ]}
            arrayOfText={[
              "We use your information to provide and enhance our B2B SaaS platform and services.",
              "We may use your contact information to send important notices, updates, and marketing materials related to our services.",
              "Your information helps us respond to your customer support requests and improve our services.",
            ]}
          />

          <RenderContentHeading
            title={"4. Information Sharing:"}
            arrayOfHeader={[
              "Third-Party Service Providers:",
              "Legal Compliance:",
            ]}
            arrayOfText={[
              "We may share your information with third-party service providers to assist us in delivering and improving our services.",
              "We may disclose your information to comply with legal obligations or in response to lawful requests.",
            ]}
          />

          <RenderContent
            title={"5. Data Security:"}
            arrayOfText={[
              "We employ industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, and destruction.",
            ]}
          />

          <RenderContent
            title={"6. Your Choices:"}
            arrayOfText={[
              "You may update your account information and communication preferences through your account settings.",
              "You can opt out of promotional communications by following the instructions in our emails or contacting us.",
            ]}
          />

          <RenderContent
            title={"7. Cookies and Similar Technologies:"}
            arrayOfText={[
              "We use cookies and similar technologies to enhance your experience, analyze usage patterns, and personalize content.",
            ]}
          />

          <RenderContent
            title={"8. International Data Transfers:"}
            arrayOfText={[
              "Your information may be processed and stored in countries different from your own. By using our services, you consent to the transfer of your information.",
            ]}
          />

          <RenderContent
            title={"9. Updates to this Privacy Statement:"}
            arrayOfText={[
              "We may update this Privacy Statement periodically. We will notify you of any material changes by posting the updated statement on our website.",
            ]}
          />

          <RenderContent
            title={"10. Contact Information:"}
            arrayOfText={[
              "If you have any questions or concerns about our Privacy Statement, please contact us at info@openturf.in.",
            ]}
          />
          <div className="mt-2">
            <label className="text-base font-semibold">
              By using B2B SaaS platform, you agree to the terms outlined in
              this Privacy Statement.
            </label>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default PrivacyPolicy;
