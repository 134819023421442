import React, { useState, useEffect, useRef } from "react";
import "../../styles/contentStyles.css";
import { YOUTUBE_HOMEPAGE } from "../../config";
import BookCallBtn from "../InvoiceProcessing/BookCallBtn";

function useCountAnimation(targetValue, isVisible, duration = 2000) {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    if (!isVisible) return;

    let startValue = 0;
    const intervalTime = Math.max(Math.floor(duration / targetValue), 20);
    const incrementCounter = setInterval(() => {
      startValue += Math.ceil(targetValue / (duration / intervalTime));
      if (startValue >= targetValue) {
        setCurrentValue(targetValue);
        clearInterval(incrementCounter);
      } else {
        setCurrentValue(startValue);
      }
    }, intervalTime);

    return () => clearInterval(incrementCounter);
  }, [targetValue, isVisible, duration]);

  return currentValue;
}
function useIntersectionObserver(ref, options) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
}

function AnimatedRange({ startValue, endValue, label, isVisible }) {
  const startCount = useCountAnimation(startValue, isVisible);
  const endCount = useCountAnimation(endValue, isVisible);

  return (
    <div className="text-center flex-shrink-0 mx-8 mt-6 md:mt-0">
      <div className="text-xl font-bold mb-4 md:mb-2 text-[#2E0040]">
        {startCount}%-{endCount}%
      </div>
      <div className="text-lg text-[#2E0040] font-semibold">{label}</div>
    </div>
  );
}

function AnimatedDualRange({ part1Value, part2Value, label, isVisible }) {
  const part1Count = useCountAnimation(part1Value, isVisible);
  const part2Count = useCountAnimation(part2Value, isVisible);

  return (
    <div className="text-center flex-shrink-0 mx-8 mt-7 md:mt-0 ">
      <div className="text-xl font-bold  mb-4 md:mb-2 text-[#2E0040]">
        {part1Count} min/{part2Count} pages
      </div>
      <div className="text-lg text-[#2E0040] font-semibold">{label}</div>
    </div>
  );
}

function StatCard({ value, label, isVisible }) {
  const currentValue = useCountAnimation(
    parseInt(value.replace(/\D/g, "")),
    isVisible
  );

  return (
    <div className="text-center flex-shrink-0 mx-8 mt-3 md:mt-0">
      <div className="text-xl font-bold mb-2 text-[#2E0040]">
        {currentValue.toLocaleString()}+
      </div>
      <div className="text-lg text-[#2E0040] font-semibold">{label}</div>
    </div>
  );
}

function StatsInfo({ isVisible }) {
  const stats = [
    { type: "normal", value: "9818+", label: "Pages Processed" },
    { type: "normal", value: "50+", label: "Document Type" },
    { type: "normal", value: "350+", label: "Hours Saved" },
    { type: "range", value1: 98, value2: 99, label: "Accuracy Rate" },
    { type: "dual", value1: 1, value2: 10, label: "Avg Processing Time" },
  ];

  return (
    <div className="relative overflow-hidden bg-[#F1E0F9] p-5 shadow-md">
      <div className="flex flex-col md:flex-row whitespace-nowrap">
        {stats.map((stat, index) => {
          if (stat.type === "normal") {
            return (
              <StatCard
                key={index}
                value={stat.value}
                label={stat.label}
                isVisible={isVisible}
              />
            );
          } else if (stat.type === "range") {
            return (
              <AnimatedRange
                key={index}
                startValue={stat.value1}
                endValue={stat.value2}
                label={stat.label}
                isVisible={isVisible}
              />
            );
          } else if (stat.type === "dual") {
            return (
              <AnimatedDualRange
                key={index}
                part1Value={stat.value1}
                part2Value={stat.value2}
                label={stat.label}
                isVisible={isVisible}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

function AIContent() {
  const ref = useRef(null);
  const isVisible = useIntersectionObserver(ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  return (
    <div ref={ref} className="bg-white rounded-lg shadow-md">
      <div className="text-4xl font-bold flex justify-center p-5 text-[#2E0040]">
        Turn Unstructured Documents into Insights
      </div>
      <div className="flex flex-col lg:flex-row w-full lg:items-center p-5">
        <div className="w-full lg:w-1/2 rounded-lg mb-5">
          <iframe
            className="w-full h-[250px] lg:h-[350px] rounded-lg"
            src={`https://www.youtube.com/embed/${YOUTUBE_HOMEPAGE}?autoplay=0&cc_lang_pref=en&cc_load_policy=1&controls=0&rel=0&hl=en&enablejsapi=1&origin=https://support.google.com&widgetid=1`}
            frameBorder="0"
            allowFullScreen="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            title="Demo Video"
          />
        </div>

        <div className="w-full lg:w-1/2 flex flex-col justify-center lg:ml-7">
          <div className="text-base text-[#1C1917] mb-10">
            Fexo is a real-time SaaS platform that automates document-intensive
            processes by transforming unstructured data into actionable insights
            at scale.
          </div>
          <div className="text-base text-[#1C1917]">
            We drive productivity of BFSI enterprises, automating workflows of
            unstructured documents (paper, scanned PDFs, images, emails, etc.),
            where volumes and turnaround times are key performance indicators.
          </div>

          <div className="flex justify-center mt-10 w-full">
            <BookCallBtn className=" w-64 h-16 z-50" text ="Book a demo" />
          </div>
        </div>
      </div>
      <StatsInfo isVisible={isVisible} />
    </div>
  );
}

export default AIContent;
