import { CheckCircleOutline } from "@mui/icons-material";
import React from "react";

function ContentInPoints({ rows }) {
  return (
    <div className="grid lg:grid-cols-2 lg:gap-x-5 w-full">
      {rows.map((row, i) => {
        return (
          <div className="w-full" key={i}>
            {row.map((column, index) => {
              return (
                <div
                  key={index}
                  className="flex w-full h-12 justify-start items-center text-start"
                >
                  <CheckCircleOutline className="text-orange-400 mr-2" />
                  <p className="text-stone-900 text-base leading-tight">
                    {column}
                  </p>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default ContentInPoints;
