import React, { useEffect } from "react";
import articleData from "../../collections/article-details.json";
import { CheckCircleOutline } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import NavbarContent from "../Navbar/NavbarContent";
import { useNavigate } from "react-router-dom";
import streamline from "../../assets/streamline.png";
import audit from "../../assets/auditing.png";
import revolutionizing from "../../assets/revolutionizing-accounts.png";
import efficiency from "../../assets/efficiency.png";
import power from "../../assets/power.png";
import synergy from "../../assets/synergy.png";
import revolutionizingInvoice from "../../assets/revolutionizingInvoice.png";
import streamlinedBusiness from "../../assets/streamlinedBusiness.png";
import costSavings from "../../assets/costSavings.png";
import enchancingVendor from "../../assets/enchancingVendor.png";
import scalingBusiness from "../../assets/scalingBusiness.png";
import ensuringCompliance from "../../assets/ensuringCompliance.png";
import MiddleContainer from "../../container/MiddleContainer";
import Footer from "../Footer";

function ArticlePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { articleId } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const article = articleData?.articleDescription?.find(
    (item) => item.id === articleId
  );

  if (!article) {
    return <p>Article not found</p>;
  }

  const { title, date, categories, content, image } = article;

  const getImage = (imageName) => {
    switch (imageName) {
      case "streamline":
        return streamline;
      case "audit":
        return audit;
      case "revolutionizing":
        return revolutionizing;
      case "efficiency":
        return efficiency;
      case "power":
        return power;
      case "synergy":
        return synergy;
      case "revolutionizingInvoice":
        return revolutionizingInvoice;
      case "streamlinedBusiness":
        return streamlinedBusiness;
      case "costSavings":
        return costSavings;
      case "enchancingVendor":
        return enchancingVendor;
      case "scalingBusiness":
        return scalingBusiness;
      case "ensuringCompliance":
        return ensuringCompliance;
      default:
        return null;
    }
  };

  return (
    <div>
      <NavbarContent>
        <ul className="navbar-nav">
          <li className="nav-item">
            <button
              className="w-40 h-12 bg-orange-500 rounded-lg shadow-xl text-white text-lg"
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </li>
        </ul>
      </NavbarContent>

      <div className="pt-24 bg-zinc-100 min-h-screen p-8">
        <div className="middle-container bg-zinc-100 pt-5 mb-5">
          <div className="sub-heading text-[#2E0040] py-6">{title}</div>
          <div className="flex items-center space-x-2 text-sm text-gray-500 mb-3">
            {categories && (
              <>
                <span>{categories.join(", ")}</span>
                <span>•</span>
              </>
            )}
            <span>Posted {date}</span>
          </div>

          <div className="mb-8">
            {getImage(image) ? (
              <img
                src={getImage(image)}
                alt="Article visualization"
                className="w-[500px] h-96 rounded-md shadow-md"
              />
            ) : (
              <p>No image available</p>
            )}
          </div>

          <div className="space-y-8">
            <div className="text-stone-900 text-lg">{content.intro}</div>
            {content?.sections.map((section, index) => (
              <div key={index}>
                <div className="font-semibold my-3 text-2xl">
                  {section?.heading}
                </div>
                {section?.points ? (
                  <div className="space-y-4">
                    {section?.points?.map((point, idx) => (
                      <div key={idx} className="flex">
                        <CheckCircleOutline className="text-orange-400 mr-2 items-center" />

                        <div className="text-stone-900 text-lg">
                          <span className="font-bold">{point?.title}</span>{" "}
                          <span className="font-normal">{point?.text}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-stone-900 text-lg">{section?.text}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <MiddleContainer bgColor={"bg-[#6A2588]"}>
        <Footer />
      </MiddleContainer>
    </div>
  );
}

export default ArticlePage;
