import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../components/Footer";
import NavbarContent from "../components/Navbar/NavbarContent";
import { Element } from "react-scroll";
import getURL from "../routes/api-urls";
import MiddleContainer from "../container/MiddleContainer";
import SnackAlert from "../components/SnackAlert";
import useSnack from "../hooks/useSnack";
import "../styles/extractedPage.css";
import FoundUsefull from "../components/Playground/FoundUsefull";
import { convertJSONToCSV } from "../utils/jsonToCSV";
import useMenu from "../hooks/useMenu";
import { metadatarService } from "../services/metadata.service";
import { field_types, form_types } from "../utils/constant";
import { getValueFromObject } from "../utils/helper";
import Label from "../components/Label";
import FloatingDocumentViewer from "../components/Playground/FloatingDocumentViewer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ExportButton = ({ data, formFields }) => {
  const { menuRef, menuClass, toggleMenu } = useMenu();

  const handleExport = () => {
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `XT Result.json`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleExportAsCSV = () => {
    const csv = convertJSONToCSV(data, formFields);
    const blob = new Blob([csv], { type: "text/csv" });
    const csvURL = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "invoice.csv";
    link.click();

    URL.revokeObjectURL(csvURL);
  };

  return (
    <div
      disabled={!data}
      className="relative text-center cursor-pointer w-40 h-12 bg-[#6A2588] rounded-lg shadow-xl text-white text-lg p-[10px] disabled:opacity-30"
      onClick={toggleMenu}
    >
      <p>Export as</p>
      <div
        className={`${menuClass} cursor-default absolute flex flex-col left-0 top-12 w-40 gap-2 items-start p-2.5 border border-purple-900 bg-zinc-100 shadow-lg text-black rounded-md`}
        ref={menuRef}
      >
        <button
          className="border w-full p-1 text-white bg-[#6A2588] rounded-md"
          onClick={() => handleExport()}
        >
          JSON
        </button>
        <button
          className="border w-full p-1 text-white bg-[#6A2588] rounded-md"
          onClick={() => handleExportAsCSV()}
        >
          CSV
        </button>
      </div>
    </div>
  );
};

function RenderFormField({ field_name, isExtractedDataLoading, value, type }) {
  return (
    <div className="mr-4">
      <Label>{field_name}</Label>
      {isExtractedDataLoading ? (
        <Skeleton
          variant="rect"
          className="rounded-md"
          width={"14rem"}
          height={"3rem"}
        />
      ) : type === field_types.daterange ? (
        <div className="flex items-center w-60 mt-1 gap-4">
          <Label>
            From:{" "}
            <span className="text-sm text-black font-semibold">
              {value.from}
            </span>
          </Label>
          <Label>
            To:{" "}
            <span className="text-sm text-black font-semibold">{value.to}</span>
          </Label>
        </div>
      ) : (
        <Label
          id={field_name}
          className="text-sm text-black font-semibold p-2 w-56"
        >
          {value}
        </Label>
      )}
    </div>
  );
}

function ExtractionPage() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const firstUpdate = useRef(true);
  const [extractedData, setExtractedData] = useState(false);
  const { showMessage, getProps } = useSnack();
  const [formFields, setFormFields] = useState(false);
  const [jobFile, setJobFile] = useState(null);
  const formFieldsRef = useRef(false);
  const extractionStage = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN_PLAYGROUND}`,
      },
    };
    const getJobDetails = () => {
      axios
        .get(`${getURL("OCR").getResult}/${jobId}`, config)
        .then(({ data }) => {
          if (formFieldsRef.current === false) {
            metadatarService.getFormFields(data.result.docType).then((data) => {
              formFieldsRef.current = true;
              setFormFields(data.result);
            });
          }
          if (data.result?.isProcessing) {
            if (extractionStage.current !== data.result.status) {
              extractionStage.current = data.result.status;
              showMessage("info", data.result.status);
            }
            setTimeout(() => getJobDetails(), 2000);
          } else {
            showMessage("success", "Extraction Completed");
            setExtractedData(data.result);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          showMessage("error", error.message);
        });
    };

    const getJobFile = (count = 0) => {
      axios
        .get(`${getURL("OCR").getFile}/${jobId}`, config)
        .then(({ data }) => {
          setJobFile(data.result);
        })
        .catch((error) => {
          console.log("error: ", error);
          if (count < 5) {
            setTimeout(() => getJobFile(count + 1), 1000);
          }
        });
    };

    if (firstUpdate.current) {
      getJobDetails();
      getJobFile();
      firstUpdate.current = false;
    }
  }, [jobId]);

  return (
    <>
      <NavbarContent>
        <div className="flex space-x-8 items-center">
          <ExportButton data={extractedData} formFields={formFields} />
          <button
            className="w-40 h-12 bg-[#6A2588] rounded-lg shadow-xl text-white text-lg"
            onClick={() => navigate("/playground")}
          >
            Back
          </button>
        </div>
      </NavbarContent>
      <FloatingDocumentViewer jobFile={jobFile} />
      <SnackAlert {...getProps} />
      <MiddleContainer bgColor={"bg-zinc-100 pt-28"}>
        <div className="flex justify-between">
          <h1 className="text-[#2E0040] sub-heading font-serif">
            Experience Fexo Document Extraction
          </h1>
        </div>
        <div className="space-x-10 mt-10">
          <div className="w-full flex flex-wrap gap-6">
            {formFields === false ? null : (
              <div>
                {formFields.sections.map((section, i) => {
                  if (
                    section.type !== form_types.form ||
                    section.show !== true
                  ) {
                    return null;
                  }
                  return (
                    <div
                      key={section.label}
                      className="w-full bg-gray-200 p-2 rounded mb-2"
                    >
                      <h3 className="mt-2 text-base text-[#2E0040] font-semibold">
                        {section.label} :
                      </h3>
                      <div className="w-full flex flex-wrap gap-6 p-3">
                        {section.fields.map(({ label, id, type }) => (
                          <RenderFormField
                            key={id}
                            field_name={label}
                            isExtractedDataLoading={extractedData === false}
                            value={getValueFromObject(
                              extractedData,
                              section.parent + "." + id
                            )}
                            type={type}
                          />
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {extractedData?.structuredTables && (
          <Element name="table-content">
            <div className="mt-8">
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {extractedData?.structuredTables[0].header?.map(
                        (colmn, i) => (
                          <StyledTableCell key={i}>{colmn}</StyledTableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {extractedData?.structuredTables[0].rows?.map((row, i) => (
                      <StyledTableRow key={i}>
                        {row.map((item, j) => (
                          <StyledTableCell key={j} component="th" scope="row">
                            {item}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Element>
        )}
      </MiddleContainer>
      <MiddleContainer bgColor={"bg-white"}>
        <FoundUsefull />
      </MiddleContainer>
      <Element name="contact">
        <MiddleContainer bgColor={"bg-[#2E0040]"}>
          <Footer />
        </MiddleContainer>
      </Element>
    </>
  );
}

export default ExtractionPage;
