import React, { useEffect, useState } from "react";
import NavbarContent from "../components/Navbar/NavbarContent";
import { useNavigate } from "react-router-dom";
import MiddleContainer from "../container/MiddleContainer";
import PointsCard from "../components/Playground/PointsCard";
import { ReactComponent as Image1 } from "../assets/up_image_1.svg";
import { ReactComponent as Image2 } from "../assets/up_image_2.svg";
import { ReactComponent as Image3 } from "../assets/up_image_3.svg";
import Footer from "../components/Footer";
import UploadFile from "../components/Upload/UploadFile";
import SampleInvoiceCard from "../components/SampleInvoiceCard";
import useSnack from "../hooks/useSnack";
import SnackAlert from "../components/SnackAlert";
import MobileViewWarning from "../components/Warning/MobileViewWarning";
import getURL from "../routes/api-urls";

function PlaygroundPage() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  const points = [
    [
      "Drop or upload an invoice not more than 3 pages",
      'After upload click "Submit"',
    ],
    [
      "Wait for the platform to process your invoice.",
      "This may take a few moments.",
    ],
    [
      "After extraction review the data",
      "Click on Export to download the data as CSV or JSON.",
    ],
  ];

  const { showMessage, getProps } = useSnack();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = window.innerWidth <= 1024;
  if (isMobile) {
    return <MobileViewWarning />;
  }

  return (
    <div>
      <NavbarContent>
        <ul className="navbar-nav">
          <li className="nav-item">
            <button
              className="w-40 h-12 bg-[#6A2588] rounded-lg shadow-xl text-white text-lg"
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </li>
        </ul>
      </NavbarContent>
      <SnackAlert {...getProps} />
      <MiddleContainer bgColor={"bg-zinc-100 pt-28"}>
        <div className="text-[#2E0040] sub-heading">
          Experience Fexo Document Extraction
        </div>
        <div className="flex justify-between mt-8 space-x-20">
          <PointsCard
            Icon={Image1}
            title={"Upload Your Document"}
            points={points[0]}
          />
          <PointsCard
            Icon={Image2}
            title={"Processing Your Document"}
            points={points[1]}
          />
          <PointsCard
            Icon={Image3}
            title={"Review & Download Data"}
            points={points[2]}
          />
        </div>
      </MiddleContainer>
      <MiddleContainer bgColor={"bg-white"}>
        <div className="flex space-x-6 h-full">
          <div className="w-7/12">
            <UploadFile
              file={file}
              setFile={setFile}
              isCheck={true}
              showMessage={showMessage}
              url={getURL("OCR").extract}
              token={process.env.REACT_APP_ACCESS_TOKEN_PLAYGROUND}
            />
          </div>
          <div className="w-5/12">
            <SampleInvoiceCard />
          </div>
        </div>
      </MiddleContainer>
      <MiddleContainer bgColor={"bg-[#2E0040]"}>
        <Footer />
      </MiddleContainer>
    </div>
  );
}

export default PlaygroundPage;
