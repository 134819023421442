import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarContent from "../components/Navbar/NavbarContent";
import CreditRiskAssessment from "./CreditRiskAssessment";
import KYCManagement from "./KYCManagement";
import TradeFinance from "./TradeFinance";
import RegulatoryCompliance from "./RegulatoryCompliance";
import CustomerSupport from "./CustomerSupport";
import MiddleContainer from "../container/MiddleContainer";
import Footer from "../components/Footer";

function ExplorePage() {
  const navigate = useNavigate();
  let location = useLocation();
  const [contentId, setContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setContent(location.pathname);
  }, [location]);

  const SwitchContent = ({ contentId }) => {
    switch (contentId) {
      case "/creaditassessment":
        return <CreditRiskAssessment />;
      case "/kycmanagement":
        return <KYCManagement />;
      case "/tradefinance":
        return <TradeFinance />;
      case "/regulatorycomplaince":
        return <RegulatoryCompliance />;
      case "/customersupport":
        return <CustomerSupport />;
      default:
        return null;
    }
  };

  

  return (
    <div>
      <NavbarContent>
        <ul className="navbar-nav">
          <li className="nav-item">
            <button
              className="w-40 h-12 bg-orange-500 rounded-lg shadow-xl text-white text-lg"
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </li>
        </ul>
      </NavbarContent>
      <div className="pt-24 bg-zinc-100 min-h-screen p-8">
        <div className="middle-container bg-zinc-100 pt-5">
          <SwitchContent contentId={contentId} />
        </div>
      </div>
      <MiddleContainer bgColor={"bg-[#6A2588]"}>
        <Footer />
      </MiddleContainer>
    </div>
  );
}

export default ExplorePage;
