import React from "react";

function CookiePolicy() {
  return (
    <div className="text-justify">
      <p className="text-stone-900 text-base font-normal ">
        Effective Date: 10th January 2024
        <br />
        <br />
        Fexo Websites utilize cookies to personalize your user experience and
        ensure uninterrupted services. By using this Website, certain
        information about your usage is automatically collected through cookies.
        It is advisable to read and make informed decisions regarding your use
        of this Website.
        <br />
        <br />
        Understanding Cookies and Their Functions
        <br />
        <br />
        In order to provide a tailored experience and remember your preferences,
        this Website's servers need to uniquely identify you. Cookies, small
        text files containing character strings, are placed on your device. Upon
        your next visit, these cookies are sent by your browser to our servers,
        enabling device identification and a customized response.
        <br />
        <br />
        Types of Cookies Used
        <br />
        <br />
        Fexo Website employs the following types of cookies:
        <br />
        <br />
        <ul>
          <li>
            Session Cookies: Temporary cookies created each time you use the
            website and deleted when your browser is closed. They remember your
            preferences as you navigate between pages.
          </li>
          <br />
          <br />
          <li>
            Persistent Cookies: Help identify and recall your settings and
            preferences during each visit, ensuring faster and more convenient
            access. Personalization is enhanced, and repeated logins may be
            unnecessary.
          </li>
          <br />
          <br />
          <li>
            Third-Party Cookies: Certain website components provided by third
            parties set cookies to facilitate their services. Refer to the
            third-party cookie policy for details.
          </li>
          <br />
          <br />
        </ul>
        Control and Management of Cookies
        <br />
        <br />
        By using the Fexo Websites, you agree to the storage of cookies on your
        device. While you can manage cookies through various methods, some are
        essential for the Website's proper functioning. Adjusting cookie
        settings may impact specific features or render them unavailable.
        <br />
        <br />
        Control cookies through your browser settings.
        <br />
        <br />
        Updates to Cookie Policy
        <br />
        <br />
        This Cookie Policy will undergo periodic updates, with the latest
        revision date mentioned above. Stay informed about how this Website's
        cookies are utilized by checking this policy regularly.
        <br />
        <br />
      </p>
    </div>
  );
}

export default CookiePolicy;
