import React from "react";
import ContentInPoints from "../ContentInPoints";
import TrialBtn from "./TrialBtn";
function Features() {
  const keybenefit_points = [
    [
      "Advanced Al based Document Extraction",
      "No pre-training required",
      "Customizable Data Classification",
      "Connectors for 3rd Party Integrations",
      
    ],
    [
      "100% data security",
      "Human-in-the-loop",
      "Rules Engine",
      "API-based platform",
    ],
  ];
  return (
    <div>
      <h2 className="text-[#2E0040] sub-heading mb-5">Features</h2>
      <p className="text-black text-base font-normal tracking-wide mb-5">
        Fexo revolutionizes your financial accounting process - precise data
        capture from documents, comprehension of context and continual
        auto-refinement to improve accuracy. With augmented data classification,
        Fexo assures that the accounting entries are always done as intended by
        you.
      </p>
      <ContentInPoints rows={keybenefit_points} />
      <div className="flex w-full justify-center mt-10">
        <TrialBtn className={"w-64 h-16"} />
      </div>
    </div>
  );
}

export default Features;
