import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../images";
import { Document, Page } from "react-pdf";

function UploadFile2({ setFile, isCheck, showMessage }) {
  const [newFile, setNewFile] = useState(false);
  const onDrop = async (acceptedFiles) => {
    let maxSize = 2_048_000;
    if (isCheck) maxSize = 256_000;

    if (!acceptedFiles[0].name.toUpperCase().includes(".PDF")) {
      showMessage("warning", "Unsupported Document!!!");
      return;
    }
    if (acceptedFiles[0].size > maxSize) {
      showMessage("warning", "File exceeds maximum size!!!");
      return;
    }
    setNewFile(acceptedFiles[0]);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    let maxPage = 6;
    if (isCheck) maxPage = 3;
    console.log("Pages: ", numPages);
    if (numPages > maxPage) {
      showMessage("warning", "Page count exceeds!!!");
      return;
    }
    setFile(newFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: `image/*, application/pdf`,
  });

  return (
    <>
      <div
        className="w-full h-72 flex flex-col items-center justify-center border border-dashed border-gray-400 rounded-md"
        {...getRootProps()}
      >
        <UploadIcon />
        <div className="text-center">
          <input {...getInputProps()} />
          <label
            className="text-[#2E0040] text-2xl font-normal"
            style={{ minWidth: "max-content" }}
          >
            <em
              style={{ cursor: "pointer" }}
              className="text-blue-600 text-2xl font-normal"
            >
              Click here
            </em>{" "}
            to upload a file
          </label>
        </div>
      </div>
      <Document
        file={newFile}
        onLoadSuccess={onDocumentLoadSuccess}
        className="hidden"
      >
        <Page pageNumber={1} />
      </Document>
    </>
  );
}

export default UploadFile2;
