import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Terms from "../components/StaticComponents/Terms";
import GDPR from "../components/StaticComponents/GDPR";
import Privacy from "../components/StaticComponents/Privacy";
import Subprocessors from "../components/StaticComponents/Subprocessors";
import SecurityPolicy from "../components/StaticComponents/SecurityPolicy";
import CookiePolicy from "../components/StaticComponents/CookiePolicy";
import ServiceLevels from "../components/StaticComponents/ServiceLevels";
import NavbarContent from "../components/Navbar/NavbarContent";
import Footer from "../components/Footer";
import MiddleContainer from "../container/MiddleContainer";

const contents_list = [
  {
    label: "Terms of Service",
    path: "/terms-of-service",
  },
  {
    label: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    label: "Subprocessors",
    path: "/subprocessors",
  },
  {
    label: "GDPR",
    path: "/gdpr",
  },
  {
    label: "Security Policy",
    path: "/security-policy",
  },
  {
    label: "Cookie policy",
    path: "/cookie-policy",
  },
  {
    label: "Service Levels",
    path: "/service-levels",
  },
];

function StaticPage() {
  const navigate = useNavigate();
  let location = useLocation();
  const [contentId, setContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setContent(location.pathname);
  }, [location]);

  const SwitchContent = ({ contentId }) => {
    switch (contentId) {
      case "/terms-of-service":
        return <Terms />;
      case "/privacy-policy":
        return <Privacy />;
      case "/subprocessors":
        return <Subprocessors />;
      case "/gdpr":
        return <GDPR />;
      case "/security-policy":
        return <SecurityPolicy />;
      case "/cookie-policy":
        return <CookiePolicy />;
      case "/service-levels":
        return <ServiceLevels />;
      default:
        return null;
    }
  };

  return (
    <div>
      <NavbarContent>
        <ul className="navbar-nav">
          <li className="nav-item">
            <button
              className="w-40 h-12 bg-orange-500 rounded-lg shadow-xl text-white text-lg"
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </li>
        </ul>
      </NavbarContent>
      <div className="pt-24 bg-zinc-100 min-h-screen">
        <div className="w-full flex justify-between middle-container">
          {contents_list.map((ele) => {
            return (
              <button
                key={ele.path}
                className={
                  location.pathname === ele.path
                    ? "border-b-4 border-purple-700"
                    : "border-b-2"
                }
                onClick={() => navigate(ele.path)}
              >
                {ele.label}
              </button>
            );
          })}
        </div>
        <div className="middle-container bg-zinc-100 pt-5">
          <SwitchContent contentId={contentId} />
        </div>
        <MiddleContainer bgColor={"bg-[#2E0040]"}>
          <Footer />
        </MiddleContainer>
      </div>
    </div>
  );
}

export default StaticPage;
