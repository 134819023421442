import React from "react";

function ImageCard({ Icon, title1, title2, content }) {
  return (
    <div className="max-w-[30rem] p-8 flex justify-center justify-self-center border border-gray-100 rounded-lg shadow-lg bg-white">
      <div>
        <div className="flex justify-center">
          <Icon />
        </div>
        <p className="text-center my-5 text-xl font-bold leading-loose tracking-wide">
          {/* <span className="text-[#6A2588] text-xl font-bold leading-loose tracking-wide">
            {title1}
          </span>
          <span className="text-[#2E0040] text-xl font-bold leading-loose tracking-wide">
            {" "}
          </span>
          <span className="text-stone-900 text-xl font-bold leading-loose tracking-wide">
            {title2}
          </span> */}
          {title1} {title2}
        </p>
        <p className="max-w-[22rem] text-center text-stone-900 text-base font-normal leading-relaxed tracking-wide px-2">
          {content}
        </p>
      </div>
    </div>
  );
}

export default ImageCard;
