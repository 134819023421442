import React from "react";
import BookCallBtn from "../InvoiceProcessing/BookCallBtn";
import {
  AIIcon,
  ChartGroupIcon,
  OptimizedQueryIcon,
  WorkFlowIcon,
  WebEyeIcon,
  UserRoleIcon,
} from "../../images";

const methodologyData = [
  {
    icon: <UserRoleIcon />,
    title: "Computer Vision + LLM + RAG",
    description:
      "Advanced technology stack powering document understanding without compromising data privacy",
  },
  {
    icon: <WorkFlowIcon />,
    title: "Autonomic Workflow Manager",
    description: "Build customized workflows for faster time-to-market",
  },
  {
    icon: <OptimizedQueryIcon />,
    title: "Optimized Query Framework",
    description:
      "A streamlined approach for queries management to maximize efficiency and accuracy",
  },

  {
    icon: <ChartGroupIcon />,
    title: "Analytic Engine",
    description: "Real-time actionable insights with powerful analytics engine",
  },
  {
    icon: <AIIcon />,
    title: "Conversational AI",
    description: "Communicate with documents using natural language",
  },
  {
    icon: <WebEyeIcon />,
    title: "Role-based access control",
    description:
      "User access based on roles and permissions for enhanced security and compliance",
  },
];

const Methodology = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col justify-center items-center">
        <div className="text-center text-4xl font-bold mb-5 text-[#2E0040]">
          The FEXO Methodology
        </div>
        <div className="text-center text-[#1C1917] font-semibold text-xl max-w-3xl flex justify-center">
          Modular, Cloud-Agnostic, Microservices, Multi-tenanted,
        </div>
        <div className="text-center text-[#1C1917] font-semibold text-xl mb-8 max-w-3xl flex justify-center">
          and Scalable Architecture
        </div>
      </div>
      <div className="grid gap-y-10">
        {methodologyData.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col lg:flex-row items-center bg-white p-3 ${
              index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
            }`}
          >
            <div className="w-full lg:w-1/3 flex justify-center mb-4 lg:mb-0">
              <div className="text-5xl text-[#2E0040]">{item.icon}</div>
            </div>
            <div
              className={`w-full lg:w-2/3 text-center lg:text-left  ${
                index % 2 === 0 ? "lg:ml-16" : ""
              }`}
            >
              <div className="text-3xl font-semibold text-[#2E0040] mb-5">
                {item.title}
              </div>
              <p className="text-stone-900 text-xl">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-10">
        <BookCallBtn className=" w-64 h-16 z-50" text="Book a demo" />
      </div>
    </div>
  );
};

export default Methodology;
