import React from "react";
import BookCallBtn from "../InvoiceProcessing/BookCallBtn";

function WelcomeContent() {
  return (
    <div className="w-full h-full flex flex-col md:flex-row items-center relative mb-5">
      <div className="">
        <header>
          <div className="text-[#2E0040] main-heading mb-2">
            Unstructured Documents,
          </div>
          <div className="text-[#2E0040] main-heading mb-2">
            Structured Results.
          </div>
          <div className="text-[#2E0040] main-heading mb-5">Real-Time.</div>
          <div className="text-[#2E0040] mt-12 md:text-2xl opacity-80 text-base font-bold tracking-wide leading-loos mb-2">
            Plug and Play{" "}
          </div>
          <div className="text-[#2E0040]  md:text-2xl  opacity-80 text-base font-bold tracking-wide leading-loose">
            No Training Required
          </div>
        </header>
        <div className="website-bg-image"></div>
        <div className=" mt-6  flex justify-center sm:justify-start">
          <BookCallBtn className=" mt-16 md:mt-8 w-64 h-16 z-50" text="Book a demo" />
        </div>
        
      </div>
   
    </div>
  );
}

export default WelcomeContent;
