import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { DeleteIcon, FileIcon, UploadIcon } from "../../images";
import { Document, Page } from "react-pdf";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { metadatarService } from "../../services/metadata.service";

function UploadFile({
  file,
  setFile,
  isCheck,
  showMessage,
  url,
  token,
  goto = "playground",
}) {
  const navigate = useNavigate();
  const [isUploading, setUploading] = useState(false);
  const [newFile, setNewFile] = useState(false);
  const [docTypes, setDocTypes] = useState(false);
  const [docType, setDocType] = React.useState(null);

  useEffect(() => {
    const fetchDocTypes = () => {
      metadatarService.getDocTypes().then(({ result }) => {
        setDocTypes(result);
      });
    };
    if (goto === "playground") {
      fetchDocTypes();
    }
  }, [goto]);

  const onDrop = async (acceptedFiles) => {
    let maxSize = 2_048_000;
    if (isCheck) maxSize = 256_000;

    if (!acceptedFiles[0].name.toUpperCase().includes(".PDF")) {
      showMessage("warning", "Unsupported Document!!!");
      return;
    }
    if (acceptedFiles[0].size > maxSize) {
      showMessage("warning", "File exceeds maximum size!!!");
      return;
    }
    setNewFile(acceptedFiles[0]);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    let maxPage = 6;
    if (isCheck) maxPage = 3;
    console.log("Pages: ", numPages);
    if (numPages > maxPage) {
      showMessage("warning", "Page count exceeds!!!");
      return;
    }
    setFile(newFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: `image/*, application/pdf`,
  });

  const handleDelete = (event) => {
    event.stopPropagation();
    setFile(null);
  };

  const handleFileUpload = (event) => {
    event.stopPropagation();
    if (isUploading) return;
    setUploading(true);
    let payload = new FormData();
    payload.append("files", file);
    payload.append("docType", docType);
    payload.append("client", "Playground");

    axios
      .post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data.status) {
          showMessage("success", "File Upload Success");
          navigate(`/${goto}/${data.result.id}`);
        }
        setUploading(false);
      })
      .catch((error) => {
        console.log(error);
        showMessage(
          "error",
          error?.response?.data?.message
            ? error.response.data.message
            : error.message
        );
        setUploading(false);
      });
  };

  const handleChange = (event) => {
    setDocType(event.target.value);
  };

  return (
    <>
      {file ? (
        <div className="flex justify-center items-center h-full">
          <div>
            <div className="flex py-8 px-6 border mt-2 border-gray-500 rounded-lg w-[390px] h-16 bg-white justify-between items-center">
              <div className="flex items-center">
                <FileIcon />
                <p className="ml-2 font-semibold">
                  {file.name}
                  <br />
                  <span className="font-normal">
                    {Math.round(file.size / 1024)} KBytes
                  </span>
                </p>
              </div>
              <DeleteIcon
                className="text-red-800 cursor-pointer"
                onClick={handleDelete}
              />
            </div>
            {docTypes?.length > 0 ? (
              <>
                <div className="flex space-x-5 mt-8">
                  <Box sx={{ minWidth: "242px" }} className="mb-2">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        File Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={docType}
                        label="docType"
                        onChange={handleChange}
                        disabled={!file}
                      >
                        {docTypes.map((type, i) => (
                          <MenuItem key={i} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <button
                    disabled={!docType || !file}
                    className="text-center text-white text-base font-bold leading-relaxed tracking-wide w-32 h-14 rounded-lg shadow bg-[#6A2588] p-2.5 disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={handleFileUpload}
                  >
                    {isUploading ? <CircularProgress size={24} /> : "Submit"}
                  </button>
                </div>
              </>
            ) : (
              <button
                disabled={!file}
                className="text-center text-white mt-5 text-base font-bold leading-relaxed tracking-wide w-32 h-12 rounded-lg shadow bg-[#6A2588] p-2.5 disabled:cursor-not-allowed disabled:opacity-50"
                onClick={handleFileUpload}
              >
                {isUploading ? <CircularProgress size={24} /> : "Submit"}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div
          className="w-full h-[22.7rem] flex flex-col items-center justify-center border border-dashed border-gray-400 rounded-md"
          {...getRootProps()}
        >
          <UploadIcon />
          <input {...getInputProps()} />
          <div className="text-center">
            <label
              className="text-[#2E0040] text-2xl font-normal"
              style={{ minWidth: "max-content" }}
            >
              <em
                style={{ cursor: "pointer" }}
                className="text-blue-600 text-2xl font-normal"
              >
                Drop a document
              </em>
            </label>
          </div>
        </div>
      )}
      <Document
        file={newFile}
        onLoadSuccess={onDocumentLoadSuccess}
        className="hidden"
      >
        <Page pageNumber={1} />
      </Document>
    </>
  );
}

export default UploadFile;
