import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

function RenderContent({ title, arrayOfText }) {
  return (
    <div className="mt-2">
      <label className="text-lg font-semibold">{title}</label>
      <ul className="text-base list-disc mx-6">
        {arrayOfText.map((text, i) => (
          <li key={i}>{text}</li>
        ))}
      </ul>
    </div>
  );
}

function TermsOfService({ open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <label className="text-[#2E0040] sub-heading font-serif">
          Terms of Service
        </label>
        <div className="text-base p-2 w-full overflow-y-auto h-[94%]">
          <label className="">Last Updated: 10th January 2024</label>
          <p className="mt-2">
            Welcome to OpenTurf’s Fexo Platform ("Service"). These Terms of
            Service ("Terms") govern your use of the Service provided by
            OpenTurf Technologies Private Limited ("Company," "we," or "us"). By
            accessing or using the Service, you agree to these Terms.
          </p>
          <RenderContent
            title={"1. Account Registration:"}
            arrayOfText={[
              "You must create an account to use certain features of the Service.",
              "You are responsible for maintaining the confidentiality of your account credentials.",
            ]}
          />

          <RenderContent
            title={"2. Your Responsibilities:"}
            arrayOfText={[
              "You agree to use the Service in compliance with applicable laws and these Terms.",
              "You are responsible for all activities that occur under your account.",
            ]}
          />

          <RenderContent
            title={"3. Payment and Billing:"}
            arrayOfText={[
              "Subscription fees, if applicable, will be billed in advance.",
              "You agree to pay all fees and charges associated with your account.",
            ]}
          />

          <RenderContent
            title={"4. Intellectual Property:"}
            arrayOfText={[
              "The Service and its original content, features, and functionality are owned by OpenTurf Technologies Private Limited and are protected by the intellectual property laws of India.",
            ]}
          />

          <RenderContent
            title={"5. Termination:"}
            arrayOfText={[
              "We reserve the right to terminate or suspend your account and access to the Service for any reason, without notice.",
              "You may terminate your account at any time by following the instructions on the Service.",
            ]}
          />

          <RenderContent
            title={"6. Data Privacy:"}
            arrayOfText={[
              "Our Privacy Statement governs the collection, use, and disclosure of your personal information. By using the Service, you consent to the terms of our Privacy Statement.",
            ]}
          />

          <RenderContent
            title={"7. Limitation of Liability:"}
            arrayOfText={[
              "We are not liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from your use of the Service.",
            ]}
          />

          <RenderContent
            title={"8. Indemnification:"}
            arrayOfText={[
              "You agree to indemnify and hold OpenTurf Technologies Private Limited, its affiliates, and their respective officers, directors, employees, and agents harmless from any claims, liabilities, damages, losses, or expenses arising out of your use of the Service.",
            ]}
          />

          <RenderContent
            title={"9. Modifications to the Service:"}
            arrayOfText={[
              "We reserve the right to modify, suspend, or discontinue the Service, in whole or in part, at any time without notice.",
            ]}
          />

          <RenderContent
            title={"10. Governing Law:"}
            arrayOfText={[
              "These Terms are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles, with the jurisdiction in Bangalore, Karnataka, India.",
            ]}
          />

          <RenderContent
            title={"11. Changes to Terms:"}
            arrayOfText={[
              "We may revise these Terms from time to time. The most current version will be available on our website. By continuing to use the Service after changes are made, you agree to the revised Terms.",
            ]}
          />

          <RenderContent
            title={"12. Contact Information:"}
            arrayOfText={[
              "If you have any questions about these Terms, please contact us at info@openturf.in.",
            ]}
          />

          <div className="mt-2">
            <label className="text-base font-semibold">
              By using Fexo platform, you agree to these Terms of Service.
            </label>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default TermsOfService;
