import { form_types } from "./constant";
import { getValueFromObject } from "./helper";

const getText = (data) => data?.replace(/,/g, " ");

export function convertJSONToCSV(jsonData, formFields) {
  const formattedData = [];

  formFields.sections.forEach((section) => {
    // console.log("Section:", section);
    const data = jsonData[section.parent];
    if (section.label) {
      const label = section.label + " :";
      formattedData.push(formattedData?.length > 0 ? "\n\n" + label : label);
    }

    if (section.type === form_types.form) {
      const fields = section.fields.map((field) => {
        return `\n${getText(field.label)}, ${getText(
          getValueFromObject(data, field.id)
        )}`;
      }, {});

      formattedData.push(fields);
    } else if (section.type === form_types.table) {
      const columns = section.columns.map((column) => column.label);

      formattedData.push("\n" + columns.join(","));
      data[section.index].rows.forEach((row) => {
        const item = row.map((item) => {
          if (typeof item === "string") return getText(item);
          return item;
        });
        formattedData.push("\n" + item.join(","));
      });
    }
  });

  return formattedData;
}

export const convertJSONToCSVKleeto = async (jsonData) => {
  const getText = (data) => {
    try {
      return data?.replace(/,/g, " ") || "";
    } catch (error) {
      return data;
    }
  };

  const getObjectToCSV = (data) => {
    return Object.keys(data)?.map(
      (key) => `\n${getText(key)}, ${getText(data[key])}`
    );
  };

  const csvFormData = getObjectToCSV(jsonData);
  const csv = [csvFormData];
  return csv;
};
